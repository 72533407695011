import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Button, Typography, Checkbox } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Fuse from "fuse.js"

const AddCamperDialog = ({ open, onClose, campers, onAddSelectedCampers }) => {
    const [selectedCampers, setSelectedCampers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [searchQuery, setSearchQuery] = useState(""); // State to keep track of the search query

    // Initialize Fuse with campers data whenever it changes
    const fuse = new Fuse(campers, {
        keys: ["first_name", "last_name", "date_of_birth", "gender"],
        includeScore: true,
        threshold: 0.4, // Adjust this for more or less fuzziness
    });

    // Function to update search query based on user input
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter campers based on the search query
    const filteredCampers = searchQuery
        ? fuse.search(searchQuery).map((result) => result.item)
        : campers;

    const handleToggleCamper = (camperId) => {
        const currentIndex = selectedCampers.indexOf(camperId);
        let newChecked = [...selectedCampers];

        if (currentIndex === -1) {
            newChecked.push(camperId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedCampers(newChecked);
    };

    const handleAddCampers = () => {
        onAddSelectedCampers(selectedCampers);
        // onClose(); // Close the dialog
    };

    const handleSelectCamper = (event, camperId) => {
        handleToggleCamper(camperId);
    };

    const isSelected = (camper) => selectedCampers?.indexOf(camper) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = campers.map((camper) => camper._id);
            setSelectedCampers(newSelecteds);
            return;
        }
        setSelectedCampers([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const visibleCampers = filteredCampers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    return (
        <Dialog fullScreen open={open} onClose={onClose} PaperProps={{
            style: {
                maxHeight: '90vh', // Adjust based on viewport height
                overflowY: 'auto'
            }
        }}>


            <DialogTitle>Add Campers</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    placeholder="Search campers"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                    margin="normal"
                />
                {
                    visibleCampers?.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={selectedCampers.length > 0 && selectedCampers.length < campers.length}
                                                checked={campers.length > 0 && selectedCampers.length === campers.length}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Date of Birth</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Gender</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {visibleCampers.map((camper) => (
                                        <TableRow key={camper._id}
                                            selected={isSelected(camper._id)}
                                            onClick={(event) => handleSelectCamper(event, camper._id)}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isSelected(camper._id)}
                                                />
                                            </TableCell>
                                            <TableCell>{camper.first_name}</TableCell>
                                            <TableCell>{camper.last_name}</TableCell>
                                            <TableCell>{camper.date_of_birth?.split('T')[0]}</TableCell>
                                            <TableCell>{camper.gender}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={campers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                        : <Typography variant="h6">No matching campers</Typography>
                }
                {selectedCampers.length > 0 && (
                    <Box display="flex-vertical" justifyContent="center" alignItems="center">
                        <Typography variant="h6">Selected Campers</Typography>
                        <TableContainer component={Paper} style={{ marginTop: 20, maxWidth: "100%", maxHeight: 300, overflow: 'auto' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Date of Birth</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Gender</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="center">Remove</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedCampers.map((camperId) => {
                                        const camper = campers.find(c => c._id === camperId);
                                        return (
                                            <TableRow key={camper?._id}>
                                                <TableCell>{camper?.first_name}</TableCell>
                                                <TableCell>{camper?.last_name}</TableCell>
                                                <TableCell>{camper?.date_of_birth.split('T')[0]}</TableCell>
                                                <TableCell>{camper?.gender}</TableCell>
                                                <TableCell align="center">
                                                    <Button onClick={() => handleToggleCamper(camper._id)} color="error">Remove</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
                }
            </DialogContent >
            <DialogActions>
                <Button onClick={handleAddCampers}>Add Selected</Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog >
    );
};

export default AddCamperDialog;