import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, Paper, Button, Typography, Checkbox, Container, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip, IconButton, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import theme from '../../theme.js'
import AddGroupDialog from './AddGroupDialog.js';
import { useAlert } from '../../context/AlertContext.js';

import Fuse from 'fuse.js';

const GroupsTable = ({ title, groups, campers, counselors, onAddGroup, onRemoveGroups, sessionID, isLoading }) => {
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddGroupDialogOpen, setIsAddGroupDialogOpen] = useState(false);
    const { setSuccess, setError } = useAlert();

    // Initialize visibleGroups with all groups initially
    const [visibleGroups, setVisibleGroups] = useState(groups);

    useEffect(() => {
        setVisibleGroups(groups); // Update whenever the groups data changes
    }, [groups]);


    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query.length > 0) {
            // Initialize Fuse with the groups list
            const fuse = new Fuse(groups, {
                keys: ["name"], // Specify the keys to search in
                includeScore: true // Optional: Include the score in the result
            });
            const result = fuse.search(query);
            // Map the search result to get the list of group objects
            const filteredGroups = result.map(item => item.item);
            setVisibleGroups(filteredGroups); // Update the state with the filtered groups
        } else {
            // If the search query is empty, reset to show all groups
            setVisibleGroups(groups);
        }

        setPage(0); // Reset to the first page when search changes
    };


    const handleOpenAddGroupDialog = () => {
        setIsAddGroupDialogOpen(true);
    };

    const handleCloseAddGroupDialog = () => {
        setIsAddGroupDialogOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCreateGroup = (name, selectedCamperIds, selectedCounselorIds) => {
        onAddGroup(name, selectedCamperIds, selectedCounselorIds);
        setIsAddGroupDialogOpen(false);
        setSuccess(`Successfully created group!`)
    };

    const handleRemoveSelectedGroups = () => {
        try {
            onRemoveGroups(selectedGroups)
            setSuccess(`Successfully removed groups`)
        }
        catch (err) {
            setError("Error removing selected groups" + err)
        }
    }

    const handleSelectGroup = (event, groupId) => {
        const selectedIndex = selectedGroups.indexOf(groupId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedGroups, groupId];
        } else {
            newSelected = newSelected.concat(
                selectedGroups.slice(0, selectedIndex),
                selectedGroups.slice(selectedIndex + 1)
            );
        }

        setSelectedGroups(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = groups.map((group) => group._id);
            setSelectedGroups(newSelecteds);
            return;
        }
        setSelectedGroups([]);
    };


    const isSelected = (groupId) => selectedGroups.includes(groupId);


    const viewGroupDetails = (groupId) => {
        navigate(`/sessions/${sessionID}/groups/${groupId}`);
    };

    // const filteredGroups = groups?.filter(group =>
    //     group?.name.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    const displayGroups = visibleGroups?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const buttonStyle = {
        height: '56px', // Fixed height
        width: 'fit-content'
    };
    const textFieldHeight = 56; // Adjust based on your TextField size
    const containedIconButtonStyle = {
        backgroundColor: theme.palette.primary.main, // Theme primary color
        color: theme.palette.primary.contrastText, // Contrast text color
        height: textFieldHeight,
        width: textFieldHeight,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Darker shade on hover
        },
    };

    return (
        <Container>
            <Grid container spacing={2} ailgnItems="center" justifyContent="space-between" style={{ marginBottom: '20px' }}>
                <Grid item xs={12}>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                {groups?.length > 0 ?
                    <Grid item xs={10} >
                        <Box display="flex" gap="10px" >
                            <TextField
                                variant="outlined"
                                placeholder="Search by name"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{ marginBottom: 20, width: '100%' }}
                                size="medium"
                                fullWidth
                                sx={{
                                    marginBottom: "20px",
                                    '& .MuiInputBase-input': {
                                        height: '40px', // Adjust input height as needed
                                        padding: '10px', // Adjust padding to vertically center the text
                                    },
                                }}

                            />
                        </Box>
                    </Grid >
                    : <Grid item xs={10}> </Grid>}
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box display="flex" gap="10px">
                        <Tooltip title="Add Groups">
                            <IconButton
                                variant="contained"
                                onClick={handleOpenAddGroupDialog}
                                style={containedIconButtonStyle}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        {groups?.length > 0 ?
                            <Tooltip title="Remove Groups">
                                <IconButton variant="contained" onClick={handleRemoveSelectedGroups} style={containedIconButtonStyle} disabled={selectedGroups.length === 0}> <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            : <> </>}
                        <AddGroupDialog
                            open={isAddGroupDialogOpen}
                            onClose={handleCloseAddGroupDialog}
                            campers={campers}
                            counselors={counselors}
                            onCreateGroup={handleCreateGroup}
                        />
                    </Box>
                </Grid>
                {
                    displayGroups.length > 0 ?
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={selectedGroups.length > 0 && selectedGroups.length < groups.length}
                                                    checked={groups.length > 0 && selectedGroups.length === groups.length}
                                                    onChange={handleSelectAllClick}
                                                />
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }} >Group Name</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }} >Number of Campers</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }} >Number of Counselors</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }} >Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {displayGroups.map((group) => (
                                            <TableRow
                                                key={group._id}
                                                selected={isSelected(group._id)}
                                                onClick={(event) => handleSelectGroup(event, group._id)}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isSelected(group._id)}
                                                        onChange={(event) => handleSelectGroup(event, group._id)}
                                                    />
                                                </TableCell>
                                                <TableCell>{group?.name}</TableCell>
                                                <TableCell>{group?.campers.length}</TableCell>
                                                <TableCell>{group?.counselors.length}</TableCell>
                                                <TableCell>{group?.gender}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => viewGroupDetails(group._id)}>View Details</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={visibleGroups.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Grid>
                        :
                        <Typography variant="h6">No groups found</Typography>
                }
            </Grid >
        </Container>
    );
};

export default GroupsTable;