import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box, Container, useMediaQuery, } from '@mui/material';
import theme from '../theme';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


export default function AttendanceTable({ campers, date }) {
    const navigate = useNavigate(); // Hook for navigation
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    // Function to navigate to LogsPage with camper's ID and any other data as state
    const viewLogs = (camperId) => {
        const camper = campers.find(c => c._id === camperId);
        if (camper) {
            navigate(`/camper-logs/${camperId}/date/${date}`, { state: { camper: camper } });
        }
    };

    // const columns = [
    //     { field: 'first_name', headerName: 'First Name', width: 130 },
    //     { field: 'last_name', headerName: 'Last Name', width: 130 },
    //     {
    //         field: 'date_of_birth', headerName: 'Date of Birth', width: 130, valueGetter: (params) => params.row.date_of_birth?.split('T')[0] || 'None',
    //     },
    //     { field: 'gender', headerName: 'Gender', width: 100 },
    //     {
    //         field: 'firstCheckIn', headerName: 'First Check-In', width: 150,
    //         valueGetter: (params) => (params.row.firstCheckIn?.timeStamp.split('T')[1])?.split("-")[0] || 'None',
    //     },
    //     {
    //         field: 'lastCheckIn', headerName: 'Last Check-In', width: 150,
    //         valueGetter: (params) => (params.row.lastCheckIn?.timeStamp.split('T')[1])?.split("-")[0] || 'None',
    //     },
    //     {
    //         field: 'lastCheckOut', headerName: 'Last Check-Out', width: 150,
    //         valueGetter: (params) => (params.row.lastCheckOut?.timeStamp.split('T')[1])?.split("-")[0] || 'None',
    //     },
    //     {
    //         field: 'viewLogs',
    //         headerName: 'View Logs',
    //         sortable: false,
    //         renderCell: (params) => (
    //             <Button
    //                 onClick={() => viewLogs(params.row._id)}
    //                 color="primary"
    //             >
    //                 View Logs
    //             </Button>
    //         ),
    //     },
    // ];

    const columns = [
        { field: 'first_name', headerName: 'First Name', width: isSmallScreen ? 100 : 130 },
        { field: 'last_name', headerName: 'Last Name', width: isSmallScreen ? 100 : 130 },
        { field: 'date_of_birth', headerName: 'Date of Birth', width: isSmallScreen ? 100 : 130, valueGetter: (params) => params.row.date_of_birth?.split('T')[0] || 'None' },
        { field: 'gender', headerName: 'Gender', width: isSmallScreen ? 80 : 100 },
        { field: 'firstCheckIn', headerName: 'First Check-In', width: isSmallScreen ? 100 : 150, valueGetter: (params) => (params.row.firstCheckIn?.timeStamp.split('T')[1])?.split("-")[0] || 'None' },
        { field: 'lastCheckIn', headerName: 'Last Check-In', width: isSmallScreen ? 100 : 150, valueGetter: (params) => (params.row.lastCheckIn?.timeStamp.split('T')[1])?.split("-")[0] || 'None' },
        { field: 'lastCheckOut', headerName: 'Last Check-Out', width: isSmallScreen ? 100 : 150, valueGetter: (params) => (params.row.lastCheckOut?.timeStamp.split('T')[1])?.split("-")[0] || 'None' },
        {
            field: 'viewLogs',
            headerName: 'View Logs',
            sortable: false,
            width: isSmallScreen ? 100 : 200,
            renderCell: (params) => (
                <Button onClick={() => viewLogs(params.row._id)} color="primary">View Logs</Button>
            ),
        },
    ];

    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState('all');

    useEffect(() => {
        setRows(campers);
    }, [campers]);

    useEffect(() => {
        setRows(campers);
    }, [campers]); // Sync state with props

    useEffect(() => {
        let filteredRows = campers;

        switch (filter) {
            case 'all':
                filteredRows = campers;
                break;
            case 'presentNow':
                filteredRows = campers.filter(row => row.lastCheckIn?.timeStamp && (!row.lastCheckOut || row.lastCheckOut?.timeStamp === "None"));
                break;
            case 'absentToday':
                filteredRows = campers.filter(row => !row.lastCheckIn || row.lastCheckIn?.timeStamp === "None");
                break;
            case 'stillInCamp':
                filteredRows = campers.filter(row => row.lastCheckIn && (row.lastCheckOut === "None" || row.lastCheckIn?.timeStamp > row.lastCheckOut?.timeStamp));
                break;
            case 'leftCamp':
                filteredRows = campers.filter(row => row.lastCheckOut && row.lastCheckOut?.timeStamp > row.lastCheckIn?.timeStamp);
                break;
            default:
                filteredRows = campers;
        }

        setRows(filteredRows);
    }, [filter, campers]);

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    return (
        // <Container maxWidth={false} style={{ padding: 0 }}>
        //     <Box sx={{ height: 400, width: '100%' }}>
        // <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        //     {['all', 'presentNow', 'absentToday', 'stillInCamp', 'leftCamp'].map((f) => (
        //         <Button
        //             key={f}
        //             variant={filter === f ? "contained" : "outlined"}
        //             color={filter === f ? "primary" : "inherit"}
        //             onClick={() => handleFilterChange(f)}
        //             sx={{
        //                 ...(filter === f && {
        //                     color: theme.palette.primary.contrastText,
        //                     backgroundColor: theme.palette.primary.main,
        //                     '&:hover': {
        //                         backgroundColor: theme.palette.primary.dark,
        //                     },
        //                 }),
        //             }}
        //         >
        //             {f.split(/(?=[A-Z])/).join(" ")}
        //         </Button>
        //     ))}
        // </Box>
        //         <DataGrid
        //             rows={rows}
        //             columns={columns}
        //             getRowId={(row) => row._id}
        //             pageSize={5}
        //             initialState={{ pinnedColumns: { left: ['first_name', 'last_name'], } }}
        //             sx={{
        //                 '.MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        //                     borderBottom: `1px solid ${theme.palette.divider}`,
        //                 },
        //                 '& .MuiDataGrid-columnHeaders': {
        //                     backgroundColor: theme.palette.background.paper,
        //                 },
        //             }}
        //         />
        //     </Box>
        // </Container>
        <Container maxWidth={isSmallScreen ? "sm" : false} style={{ padding: 0 }}>
            <Box sx={{ height: isSmallScreen ? 300 : 600, width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                    {['all', 'presentNow', 'absentToday', 'stillInCamp', 'leftCamp'].map((f) => (
                        <Button
                            key={f}
                            variant={filter === f ? "contained" : "outlined"}
                            color={filter === f ? "primary" : "inherit"}
                            onClick={() => handleFilterChange(f)}
                            sx={{
                                ...(filter === f && {
                                    color: theme.palette.primary.contrastText,
                                    backgroundColor: theme.palette.primary.main,
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                    },
                                }),
                            }}
                        >
                            {f.split(/(?=[A-Z])/).join(" ")}
                        </Button>
                    ))}
                </Box>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    initialState={{
                        pinnedColumns: { left: ['first_name', 'last_name'], },
                    }}
                    sx={{
                        '.MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                            borderBottom: `1px solid ${theme.palette.divider}`,
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                />
            </Box>
        </Container>
    );
}