import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, Paper, Button, Typography, Checkbox, Container, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip, IconButton, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import template from '../utils/template.csv';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Counselors from "../components/Counselors"
import theme from '../theme.js'
import BusPlan from './SessionDetailsSections/BusPlan.js';
import MealPlan from './SessionDetailsSections/MealPlan.js';
import Campers from './SessionDetailsSections/Campers.js';
import General from './SessionDetailsSections/General.js';
import Groups from './SessionDetailsSections/Groups.js';
import Attendance from './SessionDetailsSections/Attendance.js';
import { CheckIns } from './SessionDetailsSections/CheckIns.js';
import GroupIcon from '@mui/icons-material/Group';
import InfoIcon from '@mui/icons-material/Info';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import TodayIcon from '@mui/icons-material/Today';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { GridCheckCircleIcon } from '@mui/x-data-grid';

const appBarHeight = {
    xs: '56px',  // AppBar height for extra-small screens
    sm: '64px',  // AppBar height for small screens
    md: '69px',  // AppBar height for medium screens
    lg: '69px',  // AppBar height for large screens
    xl: '69px',  // AppBar height for extra-large screens
};
const marginTop = {
    xs: '21px',  // AppBar height for extra-small screens
    sm: '29px',  // AppBar height for small screens
    md: '34px',  // AppBar height for medium screens
    lg: '34px',  // AppBar height for large screens
    xl: '34px',  // AppBar height for extra-large screens
};

export default function SessionDetails() {
    const [sessionDetails, setSessionDetails] = useState(null);
    const [availableCampers, setAvailableCampers] = useState([]);
    const [busPlanCampers, setBusPlanCampers] = useState([]);
    const [busPlanAvailableCampers, setBusPlanAvailableCampers] = useState([]);
    const [mealPlanCampers, setMealPlanCampers] = useState([]);
    const [mealPlanAvailableCampers, setMealPlanAvailableCampers] = useState([]);
    const [registeredCampers, setRegisteredCampers] = useState([]);
    const [camperDirectory, setCamperDirectory] = useState([]);
    const [counselors, setCounselors] = useState([]);
    const [availableCounselors, setAvailableCounselors] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const drawerWidth = 240;

    const getInitialTabValue = () => localStorage.getItem('selectedTab') || 'view1';
    const [selectedView, setSelectedView] = useState(getInitialTabValue);


    const [editedSession, setEditedSession] = useState({ ...sessionDetails });

    const [mobileOpen, setMobileOpen] = useState(false);
    // Other state variables...

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleListItemClick = (view) => {
        setSelectedView(view);
        localStorage.setItem('selectedTab', view)
    };

    const removeCampersFromServer = async (campersToRemove) => {
        try {
            const response = await axiosPrivate({
                method: "patch",
                url: `/api/camps/sessions/${params.sessionID}/remove-campers`,
                data: campersToRemove,
            });
            console.log(response.data);
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleRemoveCampers = async (selectedCampers) => {
        // Logic to remove selected campers from the database or server
        await removeCampersFromServer(selectedCampers);

        // Filter out the removed campers from the campers state
        // console.log(registeredCampers)
        console.log("Before remove campers", registeredCampers);
        const updatedCampers = registeredCampers.filter(camper => !selectedCampers.includes(camper._id));
        // console.log(updatedCampers)
        setRegisteredCampers(updatedCampers);
        console.log("Before remove campers", registeredCampers);
        const removedCampers = registeredCampers.filter(camper => selectedCampers.includes(camper._id));
        setAvailableCampers([...availableCampers, ...removedCampers])
    };

    const onAddCamper = async (selectedCamperIds) => {
        try {
            console.log(selectedCamperIds)
            const response = await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}`,
                JSON.stringify(selectedCamperIds),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            console.log(response.data);
            // setRegisteredCampers(response.data.campers);
            const response2 = await axiosPrivate.get(`/api/camps/campers/session/${params.sessionID}`);
            console.log(response2.data);
            setRegisteredCampers(response2.data);


            const updatedAvailableCampers = availableCampers.filter(camper => !selectedCamperIds.includes(camper._id));
            setAvailableCampers(updatedAvailableCampers);
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleRemoveMealPlanCampers = async (selectedCampers) => {
        // Logic to remove selected campers from the database or server
        try {
            const response = await axiosPrivate({
                method: "patch",
                url: `/api/camps/sessions/${params.sessionID}/remove-from-meal-plan`,
                data: JSON.stringify({ camperIDs: selectedCampers }),
            });
            console.log(response.data);
        }
        catch (err) {
            console.error(err);
        }
        // Filter out the removed campers from the campers state
        // console.log(registeredCampers)
        console.log("Before remove campers", mealPlanCampers);
        const updatedCampers = mealPlanCampers.filter(camper => !selectedCampers.includes(camper._id));
        // console.log(updatedCampers)
        setMealPlanCampers(updatedCampers);
        console.log("Before remove campers", mealPlanCampers);
        const removedCampers = mealPlanCampers.filter(camper => selectedCampers.includes(camper._id));
        setMealPlanAvailableCampers([...availableCampers, ...removedCampers])
    }

    const onAddCamperToMealPlan = async (selectedCamperIds) => {
        try {
            console.log(selectedCamperIds)
            const response = await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}/add-to-meal-plan/`,
                JSON.stringify({ "camperIDs": selectedCamperIds }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            console.log(response.data);
            // setRegisteredCampers(response.data.campers);
            const response2 = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/mealPlan/campers`);
            console.log(response2.data);
            setMealPlanCampers(response2.data);


            const updatedAvailableCampers = mealPlanAvailableCampers.filter(camper => !selectedCamperIds.includes(camper._id));
            setMealPlanAvailableCampers(updatedAvailableCampers);
        }
        catch (err) {
            console.log(err)
        }
    }
    const handleRemoveBusPlanCampers = async (selectedCampers) => {
        // Logic to remove selected campers from the database or server
        try {
            const response = await axiosPrivate({
                method: "patch",
                url: `/api/camps/sessions/${params.sessionID}/remove-from-bus-plan`,
                data: JSON.stringify({ camperIDs: selectedCampers }),
            });
            console.log(response.data);
        }
        catch (err) {
            console.error(err);
        }
        // Filter out the removed campers from the campers state
        // console.log(registeredCampers)
        console.log("Before remove campers", busPlanCampers);
        const updatedCampers = busPlanCampers.filter(camper => !selectedCampers.includes(camper._id));
        // console.log(updatedCampers)
        setBusPlanCampers(updatedCampers);
        console.log("Before remove campers", busPlanCampers);
        const removedCampers = busPlanCampers.filter(camper => selectedCampers.includes(camper._id));
        setBusPlanAvailableCampers([...availableCampers, ...removedCampers])
    }

    const onAddCamperToBusPlan = async (selectedCamperIds) => {
        try {
            console.log(selectedCamperIds)
            const response = await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}/add-to-bus-plan/`,
                JSON.stringify({ "camperIDs": selectedCamperIds }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            console.log(response.data);
            // setRegisteredCampers(response.data.campers);
            const response2 = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/busPlan/campers`);
            console.log(response2.data);
            setBusPlanCampers(response2.data);


            const updatedAvailableCampers = busPlanAvailableCampers.filter(camper => !selectedCamperIds.includes(camper._id));
            setBusPlanAvailableCampers(updatedAvailableCampers);
        }
        catch (err) {
            console.log(err)
        }
    }

    const onAddCounselorToSession = async (selectedCounselorIds) => {
        try {
            console.log(selectedCounselorIds)
            const response = await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}/assign-counselors/`,
                JSON.stringify({ "counselorIds": selectedCounselorIds }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            console.log(response.data);
            // setRegisteredCampers(response.data.campers);
            const sessionCounselorsResponse = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/counselors`);
            console.log(sessionCounselorsResponse.data);
            setCounselors(sessionCounselorsResponse.data);


            const updatedAvailableCounselors = availableCounselors.filter(counselor => !selectedCounselorIds.includes(counselor._id));
            setAvailableCounselors(updatedAvailableCounselors);
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleRemoveCounselors = async (selectedCounselorIds) => {
        // Logic to remove selected campers from the database or server
        try {
            const response = await axiosPrivate({
                method: "patch",
                url: `/api/camps/sessions/${params.sessionID}/remove-counselors`,
                data: JSON.stringify({ counselorIds: selectedCounselorIds }),
            });
            console.log(response.data);
            // Filter out the removed campers from the campers state
            // console.log(registeredCampers)
            console.log("Before remove counselors", counselors);
            const updatedCounselors = counselors.filter(camper => !selectedCounselorIds.includes(camper._id));
            // console.log(updatedCampers)
            setCounselors(updatedCounselors);
            console.log("After remove counselors", counselors);
            const removedCounselors = counselors.filter(camper => selectedCounselorIds.includes(camper._id));
            setAvailableCounselors([...availableCounselors, ...removedCounselors])
        }
        catch (err) {
            console.error(err);
        }
    }

    const onCreateGroup = async (name, camperIds, counselorIds) => {
        try {
            const response = await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}/groups`, JSON.stringify({ name: name, campers: camperIds, counselors: counselorIds }));
            setGroups([...groups, response.data.group])
        }
        catch (err) {
            console.error(err);
        }
    }

    const onRemoveGroups = async (groupIds) => {
        try {
            console.log(groupIds);
            await axiosPrivate.patch(`/api/camps/sessions/${params.sessionID}/groups/`, { groupIDs: groupIds });
            const updatedGroups = groups.filter(group => !groupIds.includes(group._id));
            setGroups(updatedGroups);
        }
        catch (err) {
            console.error(err);
        }
    }

    const onDeleteSession = async (sessionId) => {
        try {
            const response = await axiosPrivate.post(`/api/camps/sessions/delete`, { sessionIds: [sessionId] });
            console.log(response.data);
            return response.data.message;
            // Handle successful deletion
            // e.g., update state, show notification, redirect, etc.
        } catch (error) {
            console.error("Error deleting session:", error);
            return error;
            // Handle error
            // e.g., show error notification
        }
    };

    // Example logic to update available campers for the meal plan
    const updateMealPlanAvailableCampers = () => {
        const newAvailableCampers = registeredCampers.filter(rc =>
            !mealPlanCampers.some(mp => mp._id === rc._id));
        setMealPlanAvailableCampers(newAvailableCampers);
    };
    // Example logic to update available campers for the meal plan
    const updateBusPlanAvailableCampers = () => {
        const newAvailableCampers = registeredCampers.filter(rc =>
            !busPlanCampers.some(mp => mp._id === rc._id));
        setBusPlanAvailableCampers(newAvailableCampers);
    };

    const updateMealPlanCampers = () => {
        const newMealPlanCampers = registeredCampers.filter(rc => mealPlanCampers.some(mp => mp._id === rc._id));
        setMealPlanCampers(newMealPlanCampers);
    }
    const updateBusPlanCampers = () => {
        const newBusPlanCampers = registeredCampers.filter(rc => busPlanCampers.some(mp => mp._id === rc._id));
        setMealPlanCampers(newBusPlanCampers);
    }
    useEffect(() => {
        updateMealPlanCampers();
        updateBusPlanCampers();
    }, [registeredCampers])


    useEffect(() => {
        updateMealPlanAvailableCampers();
    }, [registeredCampers, mealPlanCampers]);

    useEffect(() => {
        updateBusPlanAvailableCampers();
    }, [registeredCampers, busPlanCampers]);

    const onSaveSessionDetails = async (editedFields) => {
        const response = await axiosPrivate.put(`api/camps/sessions/${params.sessionID}`, JSON.stringify(editedFields));
        console.log(response.data);
        setSessionDetails(response.data);

    }
    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                // Fetch session details
                const sessionResponse = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}`);
                setSessionDetails(sessionResponse.data);

                // Fetch all campers
                const camperResponse = await axiosPrivate.get(`/api/camps/campers/`);
                setCamperDirectory(camperResponse.data);

                // Fetch campers registered in the session
                // const sessionCampersResponse = await axiosPrivate.get(`/api/camps/campers/session/${params.sessionID}`);
                setRegisteredCampers(sessionResponse.data.campers);

                // Compute available campers
                const availableCampers = camperResponse.data.filter(
                    camper => !sessionResponse.data.campers.some(
                        registeredCamper => registeredCamper._id === camper._id
                    )
                );
                setAvailableCampers(availableCampers);

                // const mealPlanCampersResponse = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/meal-plan-campers`);
                setMealPlanCampers(sessionResponse.data.meal_plan_campers);

                const mealPlanAvailableCampers = sessionResponse.data.meal_plan_campers.filter(
                    camper => !sessionResponse.data.meal_plan_campers.some(
                        registeredCamper => registeredCamper._id === camper._id
                    )
                );
                setMealPlanAvailableCampers(mealPlanAvailableCampers);

                // const busPlanCampersResponse = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/bus-plan-campers`);
                setBusPlanCampers(sessionResponse.data.bus_plan_campers);
                const busPlanAvailableCampers = sessionResponse.data.bus_plan_campers.filter(
                    camper => !sessionResponse.data.bus_plan_campers.some(
                        registeredCamper => registeredCamper._id === camper._id
                    )
                );
                setBusPlanAvailableCampers(busPlanAvailableCampers);


                // const counselorsResponse = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/counselors`);
                setCounselors(sessionResponse.data.counselors);

                const availableCounselorsResponse = await axiosPrivate.get(`/api/camps/${sessionResponse.data.camp_id}/users`);
                console.log(availableCounselorsResponse.data)
                const availableCounselors = availableCounselorsResponse.data.filter((counselor) => {
                    // Check if the user is not an Owner or an Admin
                    const isNotOwnerOrAdmin = counselor.roles.Owner === undefined && counselor.roles.Admin === undefined;

                    // Check if the user is not already a counselor in the session
                    const isNotAssignedCounselor = !sessionResponse.data.counselors.some(assignedCounselor => assignedCounselor._id === counselor._id);

                    return isNotOwnerOrAdmin && isNotAssignedCounselor;
                });
                setAvailableCounselors(availableCounselors);

                // const groupsResponse = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/groups`);
                setGroups(sessionResponse.data.groups);


            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSessionData();
    }, [params.sessionID, axiosPrivate, navigate, location]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant={isMobile ? 'temporary' : 'permanent'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        position: 'fixed',
                        // marginTop: `${appBarHeight}px`
                        mt: { xs: appBarHeight.xs, sm: appBarHeight.sm, md: appBarHeight.md, lg: appBarHeight.lg, xl: appBarHeight.xl }
                    },

                }}
            >
                <List>
                    {/* {['General', 'Attendance', 'Campers', 'Groups', 'Counselors', 'Meal Plan', 'Bus Plan', 'Check-Ins'].map((text, index) => ( */}
                    <ListItem button key={"General"} onClick={() => handleListItemClick(`view1`)}>
                        <InfoIcon sx={{ mr: 1 }} />
                        <ListItemText primary={"General"} />
                    </ListItem>
                    <ListItem button key={"Attendance"} onClick={() => handleListItemClick(`view2`)}>
                        <TodayIcon sx={{ mr: 1 }}></TodayIcon>
                        <ListItemText primary={"Attendance"} />
                    </ListItem>
                    <ListItem button key={"Campers"} onClick={() => handleListItemClick(`view3`)}>
                        <PeopleAltIcon sx={{ mr: 1 }}></PeopleAltIcon>
                        <ListItemText primary={"Campers"} />
                    </ListItem>
                    <ListItem button key={"Groups"} onClick={() => handleListItemClick(`view4`)}>
                        <GroupIcon sx={{ mr: 1 }}></GroupIcon>
                        <ListItemText primary={"Groups"} />
                    </ListItem>
                    <ListItem button key={"Counselors"} onClick={() => handleListItemClick(`view5`)}>
                        <SupervisorAccountIcon sx={{ mr: 1 }}></SupervisorAccountIcon>
                        <ListItemText primary={"Counselors"} />
                    </ListItem>
                    <ListItem button key={"Meal Plan"} onClick={() => handleListItemClick(`view6`)}>
                        <RestaurantIcon sx={{ mr: 1 }}></RestaurantIcon>
                        <ListItemText primary={"Meal Plan"} />
                    </ListItem>
                    <ListItem button key={"Bus Plan"} onClick={() => handleListItemClick(`view7`)}>
                        <DirectionsBusIcon sx={{ mr: 1 }}></DirectionsBusIcon>
                        <ListItemText primary={"Bus Plan"} />
                    </ListItem>
                    <ListItem button key={"Check-Ins"} onClick={() => handleListItemClick(`view8`)}>
                        <GridCheckCircleIcon sx={{ mr: 1 }}></GridCheckCircleIcon>
                        <ListItemText primary={"Check-Ins"} />
                    </ListItem>
                </List>
            </Drawer>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                    mt: { xs: marginTop.xs, sm: marginTop.sm, md: marginTop.md, lg: marginTop.lg, xl: marginTop.xl }
                }}
            >
                <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                    <Container maxWidth={false}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">{sessionDetails?.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {selectedView === 'view1' && <General onSave={onSaveSessionDetails} onDelete={onDeleteSession} sessionDetails={sessionDetails} isLoading={isLoading}></General>}
                        </Grid>
                        <Grid item xs={12}>
                            {selectedView === 'view2' && <Attendance sessionId={params?.sessionID}></Attendance>}
                        </Grid>
                        <Grid item xs={12}>
                            {selectedView === 'view3' &&
                                <Campers campers={registeredCampers} onRemoveCampers={handleRemoveCampers} availableCampers={availableCampers} onAddCamper={onAddCamper} isLoading={isLoading}></Campers>}
                        </Grid>
                        <Grid item xs={12}>
                            {selectedView === 'view4' && <Groups groups={groups} campers={registeredCampers} counselors={counselors} onAddGroup={onCreateGroup} onRemoveGroups={onRemoveGroups} sessionID={params?.sessionID} isLoading={isLoading}></Groups>}
                        </Grid>
                        <Grid item xs={12}>
                            {selectedView === 'view5' && <Counselors counselors={counselors} availableCounselors={availableCounselors} onAddCounselor={onAddCounselorToSession} onRemoveCounselors={handleRemoveCounselors} isLoading={isLoading}></Counselors>}
                        </Grid>
                        <Grid item xs={12}>
                            {selectedView === 'view6' && <MealPlan campers={mealPlanCampers} availableCampers={mealPlanAvailableCampers} onRemoveCampers={handleRemoveMealPlanCampers} onAddCamper={onAddCamperToMealPlan} isLoading={isLoading}></MealPlan>}
                        </Grid>
                        <Grid item xs={12}>
                            {selectedView === 'view7' && <BusPlan campers={busPlanCampers} availableCampers={busPlanAvailableCampers} onRemoveCampers={handleRemoveBusPlanCampers} onAddCamper={onAddCamperToBusPlan} isLoading={isLoading}></BusPlan>}
                        </Grid>
                        <Grid item xs={12}>
                            {selectedView === 'view8' && <CheckIns sessionId={params?.sessionID}></CheckIns>}
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Box >
    );
}
