import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box, Container } from '@mui/material';
import theme from '../theme';

const columns = [
    { field: 'first_name', headerName: 'First Name', width: 130 },
    { field: 'last_name', headerName: 'Last Name', width: 130 },
    {
        field: 'date_of_birth', headerName: 'Date of Birth', width: 130, valueGetter: (params) => params.row.date_of_birth?.split('T')[0] || 'None',
    },
    { field: 'gender', headerName: 'Gender', width: 100 },
    {
        field: 'lastMealPlan', headerName: 'Last Meal Plan Log', width: 150,
        valueGetter: (params) => (params.row.lastMealPlan?.timeStamp.split('T')[1])?.split("-")[0] || 'None',
    },
];

export default function MealPlanTable({ campers }) {
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState('all');

    useEffect(() => {
        setRows(campers);
    }, [campers]);

    useEffect(() => {
        setRows(campers);
    }, [campers]); // Sync state with props

    useEffect(() => {
        let filteredRows = campers;

        switch (filter) {
            case 'all':
                filteredRows = campers;
                break;
            case 'tookMeal':
                filteredRows = campers.filter(row => row.lastMealPlan?.timeStamp);
                break;
            case 'didNotTakeMeal':
                filteredRows = campers.filter(row => !row.lastMealPlan || row.lastMealPlan?.timeStamp === "None");
                break;
            default:
                filteredRows = campers;
        }

        setRows(filteredRows);
    }, [filter, campers]);

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ height: 400, width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                    {['all', 'tookMeal', 'didNotTakeMeal'].map((f) => (
                        <Button
                            key={f}
                            variant={filter === f ? "contained" : "outlined"}
                            color={filter === f ? "primary" : "inherit"}
                            onClick={() => handleFilterChange(f)}
                            sx={{
                                ...(filter === f && {
                                    color: theme.palette.primary.contrastText,
                                    backgroundColor: theme.palette.primary.main,
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                    },
                                }),
                            }}
                        >
                            {f.split(/(?=[A-Z])/).join(" ")}
                        </Button>
                    ))}
                </Box>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={5}
                />
            </Box>
        </Container>
    );
}