import React from 'react'
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import CheckoutForm from './CheckoutForm';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import CustomPaginationActionsTable from '../components/CustomPaginationActionsTable';



const stripePromise = loadStripe('pk_test_51HyzZVJghAUUh3f0f7kVGfBwYgVvPBPv4sN90DEoN0hlZWg9pFPAlwFxiS4DplB83GnVDvSLgIfBU2F0fEF5luos00BNAXECI6');


export default function PayPage() {
    const [clientSecret, setClientSecret] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [sessionDetails, setSessionDetails] = React.useState("");
    const [camperDetails, setCamperDetails] = React.useState([]);
    const [amount, setAmount] = React.useState("");
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();


    React.useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        const createPaymentIntent = async () => {
            console.log(params.sessionID);
            const response = await axiosPrivate.post("/api/create-payment-intent/" + params.sessionID);
            console.log(response.data);
            setClientSecret(response?.data?.clientSecret);
            setAmount(response?.data?.amount);
        }
        const getSession = async () => {
            try {
                const response = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}`);
                console.log(response.data);
                setSessionDetails(response.data);
            }
            catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        const getPendingCampers = async () => {
            try {
                const response = await axiosPrivate.get(`/api/camps/campers/session/pending-campers/${params.sessionID}`);
                console.log(response.data);
                setCamperDetails(response.data);
            }
            catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }
        createPaymentIntent();
        getSession();
        getPendingCampers();
        setIsLoading(false);
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    if (!isLoading && clientSecret) {
        return (
            <Container maxWidth="lg">
                {!isLoading && clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        {amount !== 0 ?
                            <Box>
                                <Typography variant="h4">Session Payment for {sessionDetails.name}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                                    {
                                        isLoading ?
                                            <Box>
                                                <CircularProgress />
                                            </Box>
                                            :
                                            camperDetails?.length === 0 ?
                                                <></> :
                                                <Box sx={{ width: '70%' }}>
                                                    <Typography variant="h5">Pending Campers</Typography>
                                                    <CustomPaginationActionsTable campers={camperDetails}></CustomPaginationActionsTable>
                                                </Box>
                                    }
                                    <CheckoutForm />
                                </Box>
                                <Typography variant="subtitle">Total Amount: ${amount / 100}.{amount % 100}</Typography>
                            </Box>
                            : <Box>
                                <CircularProgress />
                            </Box>
                        }
                    </Elements>
                )}
            </Container>
        )
    }
    else {
        return (<Container>
            <Typography>Camp session already paid for.</Typography>
        </Container>)
    }
}
