import React, { useState, useEffect } from 'react';
import { Container, Typography, LinearProgress, List, ListItem, ListItemText, Divider, Box } from '@mui/material';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import CustomPaginationActionsTable from '../components/CustomPaginationActionsTable';
import CustomPaginationActionsTableCounselors from '../components/CustomPaginationActionsTableCounselors';
import { DataGrid } from '@mui/x-data-grid'; // Make sure to install @mui/x-data-grid if not already done

const CheckInDetails = () => {
    const [checkInName, setCheckInName] = useState('Summer Camp Check-In');
    const [checkInProgress, setCheckInProgress] = useState(50); // This could be dynamic based on your logic
    const [campers, setCampers] = useState([]);
    const [counselors, setCounselors] = useState([]);
    const [checkedInCampers, setCheckedInCampers] = useState([]);
    const [logs, setLogs] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCheckIn = async () => {
            try {
                setIsLoading(true)
                console.log(`/api/camps/sessions/${params.sessionID}/check-ins/${params.checkInID}`)
                const response = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/check-ins/${params.checkInID}`);
                console.log(response.data);
                setCheckInName(response.data.name);
                setCheckInProgress(response.data.progress);
                setCampers(response.data.campers);
                setCounselors(response.data.counselors);
                setCheckedInCampers(response.data.checked_in);
            }
            catch (err) {
                console.error(err);
            }
            finally {
                setIsLoading(false);
            }
        }
        const fetchLogs = async () => {
            try {
                const response = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/logs/filterByCheckin/${params.checkInID}`);
                console.log(response.data);
                setLogs(response.data);
            }
            catch (err) {
                console.error(err)
            }
            finally {
                setIsLoading(false);
            }
        }
        fetchCheckIn();
        fetchLogs();
    }, []);

    const logsColumns = [
        { field: 'camperName', headerName: 'Camper', width: 200 },
        { field: 'counselorName', headerName: 'Counselor', width: 200 },
        { field: 'timeStamp', headerName: 'Timestamp', width: 200 },
        { field: 'logType', headerName: 'Log Type', width: 200 },
    ];

    const logsRows = logs.map((log, index) => ({
        id: index,
        camperName: log.camper?.first_name + " " + log.camper?.last_name,
        counselorName: log.counselor?.first_name + " " + log.counselor?.last_name,
        timeStamp: (log.time_stamp.split("T")[1]).split("-")[0],
        logType: log.log_type,
    }));

    return (
        isLoading ? <LinearProgress></LinearProgress> :
            <Container maxWidth="md" sx={{ mt: 10 }}>
                <Typography variant="h4" gutterBottom>{checkInName}</Typography>
                <Typography variant="subtitle1">{checkInProgress}</Typography>
                <Divider sx={{ my: 2 }} />

                <Typography variant="h6">Checked In Campers</Typography>
                {checkedInCampers?.length === 0 ? <Typography>No campers checked in yet...</Typography> :
                    <CustomPaginationActionsTable campers={checkedInCampers}></CustomPaginationActionsTable>
                }
                <Divider sx={{ my: 2 }} />

                <Typography variant="h6">Logs</Typography>
                {logs?.length === 0 ? <Typography>No logs to show...</Typography> :
                    <Box style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={logsRows}
                            columns={logsColumns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                        />
                    </Box>
                }

                <Divider sx={{ my: 2 }} />

                <Typography variant="h6">Campers List</Typography>
                <CustomPaginationActionsTable campers={campers}></CustomPaginationActionsTable>

                <Divider sx={{ my: 2 }} />

                <Typography variant="h6">Counselors List</Typography>
                {counselors.length === 0 ? <Typography>No counselors associated to this check-in.</Typography> :
                    <CustomPaginationActionsTableCounselors counselors={counselors}></CustomPaginationActionsTableCounselors>
                }

            </Container>
    );
};

export default CheckInDetails;
