import { useState } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';

const theme = createTheme();

const styles = {
    paper: {
        padding: 16,
        marginBottom: 16,
    },
    input: {
        display: "none",
    },
};

function AddCampersFile() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState('');
    const params = useParams();

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        // Handle file upload logic
        console.log(selectedFile);
        try {
            setIsLoading(true);
            var bodyFormData = new FormData();
            bodyFormData.append("file", selectedFile);
            const response = await axiosPrivate({
                method: "post",
                url: `/api/camps/sessions/file/${params.sessionID}`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true
            }
            );
            console.log(response.data);
            navigate(`/view-session-details/${params.sessionID}`);
        }
        catch (err) {
            console.log(err);
            setErrMsg(err.response.data[0].message);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md" spacing={2} style={{ marginTop: 20 }}>
                <Paper style={styles.paper}>
                    <Typography variant="h5" gutterBottom>
                        Add Campers from File
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <input
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                style={styles.input}
                                id="file-upload"
                                type="file"
                                onChange={handleFileInputChange}
                            />
                            <label htmlFor="file-upload">
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="primary"
                                    size="large"
                                >
                                    Select File
                                </Button>
                            </label>
                        </Grid>
                        <Grid item>
                            {selectedFile && (
                                <Typography variant="body1">
                                    Selected File: {selectedFile.name}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!selectedFile}
                                onClick={handleFileUpload}
                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper style={styles.paper}>
                    <Typography variant="body1">
                        Please follow this format for the file:
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="div">
                        <ul>
                            <li>The first row should be the column headers.</li>
                            <li>
                                The columns should be in the following order: first_name, last_name, date_of_birth, and gender.
                            </li>
                            <li>
                                Dates should be in the format yyyy-mm-dd (e.g. 2005-12-31).
                            </li>
                            <li>Gender should be M or F.</li>
                            <li>There should be no blank rows in the file.</li>
                        </ul>
                        <Typography variant="body1">
                            Sample file:
                            <a href="./sample_file.csv" download>
                                sample_file.csv
                            </a>
                        </Typography>
                    </Typography>
                </Paper>
            </Container>
        </ThemeProvider >
    );
}

export default AddCampersFile;
