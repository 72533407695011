import SessionCampersTable from "./SessionCampersTable";
import { Container, LinearProgress } from "@mui/material";

const MealPlan = ({ campers, availableCampers, onAddCamper, onRemoveCampers, isLoading }) => {
    return (
        isLoading ? <LinearProgress>
        </LinearProgress> :
            <Container component="main">
                < SessionCampersTable title="Meal Plan Opted Campers" campers={campers} availableCampers={availableCampers} onAddCamper={onAddCamper} onRemoveCampers={onRemoveCampers} > </SessionCampersTable ></Container >)
};
export default MealPlan;