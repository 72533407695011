import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


const LogsPage = () => {
    const { camperId, date } = useParams();
    const location = useLocation(); // Access location state
    const [logs, setLogs] = useState([]);

    const camper = location.state.camper; // Assuming camper object is passed in location state
    console.log(camper);
    useEffect(() => {
        const loadData = async () => {
            const fetchedLogs = camper.logs;
            setLogs(fetchedLogs.map((log, index) => ({ ...log, id: index }))); // Ensure each log has an id
        };
        loadData();
    }, [camperId]);

    const columns = [
        {
            field: 'camper', headerName: 'Camper', width: 200,
            valueGetter: (params) => params.row.camper?.first_name + " " + params.row.camper?.last_name,
        },
        {
            field: 'counselor', headerName: 'Counselor', width: 200,
            valueGetter: (params) => params.row.counselor?.first_name + " " + params.row.counselor?.last_name,
        },
        {
            field: 'timeStamp', headerName: 'Timestamp', width: 200,
            valueGetter: (params) => (params.row.timeStamp?.split("T")[1]).split("-")[0] || "None",
        },
        { field: 'logType', headerName: 'Log Type', width: 150 },
        {
            field: 'checkInName', headerName: 'Check-In Name', width: 200,
            valueGetter: (params) => params.row.check_in || "None",

        },
    ];

    return (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Typography variant="h4" gutterBottom>
                Logs for {camper.first_name} {camper.last_name} on {date}
            </Typography>
            {logs.length === 0 ? <Typography>No logs to display... </Typography>
                :
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={logs}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 25]}
                    />
                </Box>
            }
        </Container>
    );
};

export default LogsPage;
