import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Button, Typography, Checkbox } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


const AddCounselorDialog = ({ open, onClose, counselors, onAddSelectedCounselors }) => {
    const [selectedCounselors, setSelectedCounselors] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleToggleCounselor = (counselorId) => {
        const currentIndex = selectedCounselors.indexOf(counselorId);
        const newChecked = [...selectedCounselors];

        if (currentIndex === -1) {
            newChecked.push(counselorId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedCounselors(newChecked);
    };

    const handleAddCounselors = () => {
        onAddSelectedCounselors(selectedCounselors);
        // onClose(); // Close the dialog
    };

    const handleSelectCounselor = (event, counselor) => {
        const selectedIndex = selectedCounselors.indexOf(counselor);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCounselors, counselor);
        } else if (selectedIndex >= 0) {
            newSelected = newSelected.concat(selectedCounselors.slice(0, selectedIndex), selectedCounselors.slice(selectedIndex + 1));
        }

        setSelectedCounselors(newSelected);
    };

    const isSelected = (counselor) => selectedCounselors?.indexOf(counselor) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = counselors.map((counselor) => counselor._id);
            setSelectedCounselors(newSelecteds);
            return;
        }
        setSelectedCounselors([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const visibleCounselors = counselors?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <DialogTitle>Add Counselors</DialogTitle>
            <DialogContent>
                {visibleCounselors?.length > 0 ?
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selectedCounselors.length > 0 && selectedCounselors.length < counselors.length}
                                            checked={counselors.length > 0 && selectedCounselors.length === counselors.length}
                                            onChange={handleSelectAllClick}
                                        />
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Role</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visibleCounselors.map((counselor) => (
                                    <TableRow key={counselor?._id}
                                        selected={isSelected(counselor?._id)}
                                        onClick={(event) => handleSelectCounselor(event, counselor?._id)}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(counselor?._id)}
                                            />
                                        </TableCell>
                                        <TableCell>{counselor?.first_name}</TableCell>
                                        <TableCell>{counselor?.last_name}</TableCell>
                                        <TableCell>{Object.keys(counselor?.roles)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={counselors.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                    : <Typography variant="h6">No matching counselors</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddCounselors}>Add Selected</Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCounselorDialog;