import React, { useState, useEffect } from "react";
import { useNavigate, useParams, } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
    Typography,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Grid,
    Container,
    IconButton,
} from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAlert } from '../context/AlertContext';

const phoneRegex = /^\+?[1-9]\d{1,14}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function validatePhoneNumber(phone) {
    return phoneRegex.test(phone);
}

function validateEmail(email) {
    return emailRegex.test(email);
}

const EditCamperPage = () => {
    const { camperID } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const { setSuccess, setError } = useAlert();

    const [camper, setCamper] = useState({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        nfc_id: "",
        health_info: "",
        special_notes: "",
        guardians: [],
    });
    const [validationErrors, setValidationErrors] = useState([]);


    useEffect(() => {
        axiosPrivate.get(`/api/camps/campers/${camperID}`)
            .then((response) => {
                const data = response.data;
                setCamper({
                    firstName: data.first_name,
                    lastName: data.last_name,
                    dateOfBirth: data.date_of_birth.split('T')[0],
                    gender: data.gender,
                    nfc_id: data.nfc_id,
                    health_info: data.health_info,
                    special_notes: data.special_notes,
                    guardians: data.guardians || [],
                });
            })
            .catch((error) => console.log(error));
    }, [camperID, axiosPrivate]);

    const handleChange = (name, value, index = null) => {
        const updatedCamper = { ...camper };
        if (index !== null) {
            updatedCamper.guardians[index] = { ...updatedCamper.guardians[index], [name]: value };
        } else {
            updatedCamper[name] = value;
        }
        setCamper(updatedCamper);
    };

    const handleAddGuardian = () => {
        setCamper({
            ...camper,
            guardians: [...camper.guardians, { first_name: "", last_name: "", email: "", phone_number: "" }]
        });
    };

    const handleRemoveGuardian = (index) => {
        const updatedGuardians = camper.guardians.filter((_, i) => i !== index);
        setCamper({ ...camper, guardians: updatedGuardians });
    };

    const validateGuardians = (guardians) => {
        const errors = [];
        guardians.forEach((guardian, index) => {
            if (!validateEmail(guardian.email)) {
                errors.push(`Guardian ${index + 1}: Invalid email (${guardian.email})`);
            }
            if (!validatePhoneNumber(guardian.phone_number)) {
                errors.push(`Guardian ${index + 1}: Invalid phone number (${guardian.phone_number})`);
            }
        });
        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validateGuardians(camper.guardians);
        if (errors.length > 0) {
            setValidationErrors(errors);
            setError(errors[0]);
            return;
        }
        axiosPrivate.put(`/api/camps/campers/${camperID}`, camper)
            .then((response) => console.log(response.data))
            .then(() => navigate(`/view-camper-details/${camperID}`))
            .catch((error) => console.log(error));
    };

    return (
        <Container style={{ marginTop: '75px' }} maxWidth="md">
            <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh', maxWidth: '1200px', width: '100%' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">Edit Camper</Typography>
                </Grid>
                <Grid item xs={12} style={{ maxWidth: '1200px', width: '100%' }}>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', maxWidth: '1200px', width: '100%' }}>
                        <TextField
                            label="First Name"
                            variant="outlined"
                            value={camper.firstName}
                            onChange={(e) => handleChange('firstName', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Last Name"
                            variant="outlined"
                            value={camper.lastName}
                            onChange={(e) => handleChange('lastName', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Date of Birth"
                            type="date"
                            variant="outlined"
                            value={camper.dateOfBirth}
                            onChange={(e) => handleChange('dateOfBirth', e.target.value)}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                        <FormControl component="fieldset" margin="normal">
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup value={camper.gender} onChange={(e) => handleChange('gender', e.target.value)} row>
                                <FormControlLabel
                                    value="Male"
                                    control={<Radio />}
                                    label="Male"
                                />
                                <FormControlLabel
                                    value="Female"
                                    control={<Radio />}
                                    label="Female"
                                />
                                <FormControlLabel
                                    value="other"
                                    control={<Radio />}
                                    label="Other"
                                />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            label="NFC ID"
                            variant="outlined"
                            value={camper.nfc_id}
                            onChange={(e) => handleChange('nfc_id', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Health Info"
                            multiline
                            rows={6} // Adjust as necessary
                            variant="outlined"
                            value={camper.health_info}
                            onChange={(e) => handleChange('health_info', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Special Notes"
                            multiline
                            rows={6} // Adjust as necessary
                            variant="outlined"
                            value={camper.special_notes}
                            onChange={(e) => handleChange('special_notes', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <Typography variant="h6" align="center">Guardians</Typography>
                        {camper.guardians.map((guardian, index) => (
                            <div key={index} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <TextField label="First Name" variant="outlined" value={guardian.first_name} onChange={(e) => handleChange('first_name', e.target.value, index)} fullWidth margin="normal" />
                                <TextField label="Last Name" variant="outlined" value={guardian.last_name} onChange={(e) => handleChange('last_name', e.target.value, index)} fullWidth margin="normal" />
                                <TextField label="Email" variant="outlined" value={guardian.email} onChange={(e) => handleChange('email', e.target.value, index)} fullWidth margin="normal" />
                                <TextField label="Phone Number" variant="outlined" value={guardian.phone_number} onChange={(e) => handleChange('phone_number', e.target.value, index)} fullWidth margin="normal" />
                                <IconButton onClick={() => handleRemoveGuardian(index)}><RemoveCircleOutlineIcon /></IconButton>
                            </div>
                        ))}
                        <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddGuardian}>Add Guardian</Button>
                        <Button type="submit" variant="contained">
                            Save
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EditCamperPage;


