import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, Paper, Button, Typography, Checkbox, Container, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip, IconButton, MenuItem, LinearProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../theme.js'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAlert } from '../../context/AlertContext.js';

const General = ({ onSave, onDelete, sessionDetails, isLoading }) => {
    const [editedSession, setEditedSession] = useState({ ...sessionDetails });
    const [initialSession, setInitialSession] = useState({ ...sessionDetails });
    const [isEditing, setIsEditing] = useState(false); // New state for edit mode
    const navigate = useNavigate();
    const { setSuccess, setError } = useAlert();
    // const [open, setOpen] = useState(false);
    // const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'success' });
    // const showAlert = (message, severity) => {
    //     setAlertInfo({ message, severity });
    //     setOpen(true);
    // };

    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setOpen(false);
    // };


    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteSession = async () => {
        const message = await onDelete(sessionDetails._id); // Replace with your delete logic
        handleCloseDeleteDialog();
        setSuccess("Successfully deleted session. Navigating to dashboard...");
        setTimeout(() => {
            navigate("/dashboard")
        }, 3000)
    };

    useEffect(() => {
        // Update state if sessionDetails prop changes
        setEditedSession({ ...sessionDetails });
        setInitialSession({ ...sessionDetails });
    }, [sessionDetails]);

    const handleInputChange = (e) => {
        setEditedSession({ ...editedSession, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        const updatedFields = {};
        for (const key in editedSession) {
            if (editedSession[key] !== initialSession[key]) {
                updatedFields[key] = editedSession[key];
            }
        }
        onSave(updatedFields);
        setIsEditing(false); // Exit edit mode after saving
        setSuccess("Successfully updated session.");
    };

    // Manually defined list of time zones
    const timeZones = ['America/Montreal', 'America/Toronto', 'America/Vancouver',
        'UTC', 'America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles',
        'Europe/London', 'Europe/Berlin', 'Europe/Paris', 'Asia/Tokyo', 'Asia/Hong_Kong',
        // ... add more as needed
    ];

    return (
        isLoading ? <LinearProgress></LinearProgress> :
            <Container>

                <Paper sx={{ padding: 2, marginBottom: 2, marginTop: 2 }}>
                    <Typography variant="h6" >Session Details</Typography>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
                        {isEditing ?
                            (
                                <>
                                    <TextField
                                        label="Start Date"
                                        type="date"
                                        name="start_date"
                                        value={editedSession.start_date?.split('T')[0] || ''}
                                        onChange={handleInputChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        label="End Date"
                                        type="date"
                                        name="end_date"
                                        value={editedSession.end_date?.split('T')[0] || ''}
                                        onChange={handleInputChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        label="Location"
                                        type="text"
                                        name="location"
                                        value={editedSession.location || ''}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        label="Time Zone"
                                        select
                                        name="time_zone"
                                        value={editedSession.time_zone || ''}
                                        onChange={handleInputChange}
                                        sx={{ marginY: 1 }}
                                        fullWidth
                                    >
                                        {timeZones.map((zone) => (
                                            <MenuItem key={zone} value={zone}>
                                                {zone}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                    >
                                        Save Changes
                                    </Button>
                                </>
                            ) :
                            (
                                <>
                                    <Typography variant="body1">
                                        <strong>Start Date:</strong> {sessionDetails?.start_date?.split('T')[0]}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>End Date:</strong> {sessionDetails?.end_date?.split('T')[0]}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Location:</strong> {sessionDetails?.location}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Time Zone:</strong> {sessionDetails?.time_zone}
                                    </Typography>
                                </>
                            )
                        }
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setIsEditing(!isEditing)} // Toggle edit mode
                        >
                            {isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleOpenDeleteDialog}
                            sx={{ mt: 2 }}
                        >
                            Delete Session
                        </Button>
                        <Dialog
                            open={openDeleteDialog}
                            onClose={handleCloseDeleteDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Delete Session?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete this session? This action cannot be undone.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDeleteDialog} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleDeleteSession} color="error" autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box >
                </Paper >
                {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={alertInfo.severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {alertInfo.message}
                </Alert>
            </Snackbar> */}
            </Container>
    );
};
export default General;