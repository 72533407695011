import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import {
    Tooltip, IconButton, Grid, Box, Paper, Typography, Container,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    LinearProgress, Button
} from '@mui/material';
import CustomPaginationActionsTable from '../components/CustomPaginationActionsTable';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import theme from '../theme';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../context/AlertContext';

const textFieldHeight = 56; // Adjust based on your TextField size
const containedIconButtonStyle = {
    backgroundColor: theme.palette.primary.main, // Theme primary color
    color: theme.palette.primary.contrastText, // Contrast text color
    height: textFieldHeight,
    width: textFieldHeight,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark, // Darker shade on hover
    },
};

export default function Campers() {
    const [campers, setCampers] = useState([]);
    const [selectedCampers, setSelectedCampers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const { alert, resetAlert, setSuccess, setError } = useAlert();

    const handleSelectedCampersChange = (selectedIds) => {
        setSelectedCampers(selectedIds);
    };

    const handleDeleteSelectedCampers = async (selectedCamperIds) => {
        // Implement delete logic
        try {
            const response = await axiosPrivate.delete(`/api/camps/campers/`, {
                data: { camperIds: selectedCampers }
            });
            setCampers(prevCampers => prevCampers.filter(camper => !selectedCampers.includes(camper._id)));
            setSelectedCampers([]);
            setSuccess("Successfully deleted campers")

        }
        catch (err) {
            console.error(err);
            setError("Error deleting selected campers" + err)
        }
    };


    // ... existing useEffect and other methods ...

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDeleteDialog(false);
    };


    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpenDeleteDialog = () => {
        if (selectedCampers.length > 0) {
            setOpenDeleteDialog(true);
        }
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleConfirmDelete = async () => {
        await handleDeleteSelectedCampers(selectedCampers);
        handleCloseDeleteDialog();
        setSelectedCampers([]);
    };


    useEffect(() => {
        const getCampers = async () => {
            try {
                const response = await axiosPrivate.get(`/api/camps/campers/`);
                setCampers(response.data);
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                if (err.response && err.response.status === 401) {
                    navigate('/sign-in');
                }
            }
        };
        getCampers();
    }, [axiosPrivate, navigate]);

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="h4" textAlign="center">Campers</Typography>
                </Grid>
                <Grid item container xs={10} justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Tooltip title="Create Camper">
                            <IconButton
                                onClick={() => navigate("/add-camper")}
                                style={containedIconButtonStyle}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Remove Selected Campers">
                            <IconButton
                                onClick={handleOpenDeleteDialog}
                                style={containedIconButtonStyle}
                                disabled={selectedCampers.length === 0}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Upload Camper File">
                            <IconButton
                                onClick={() => navigate("/add-camper-file")}
                                style={containedIconButtonStyle}
                            >
                                <UploadFileIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <Box sx={{ width: '100%' }}> <LinearProgress /></Box>
                    </Grid>
                ) : campers?.length === 0 ? (
                    <Grid item xs={12}>
                        <Typography variant="subtitle" textAlign="center">No campers to show...</Typography>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <CustomPaginationActionsTable campers={campers} onDeleteCampers={handleDeleteSelectedCampers} onSelectedCampersChange={handleSelectedCampersChange}
                        />
                    </Grid>
                )}
            </Grid>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the selected campers? The camper(s) will be removed from all sessions they are registered in. This action is irreversible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
}
