import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Campers from './pages/Campers';
import NoPage from './pages/NoPage';
import AuthContext, { AuthProvider } from './context/AuthProvider';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import CreateSession from './pages/CreateSession';
import SessionDetails from './pages/SessionDetails';
import EditProfile from './pages/EditProfile';
import CreateCamper from './pages/CreateCamper';
import Support from './pages/Support';
import CamperDetails from './pages/CamperDetails';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './pages/CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';


// Define colors extracted from the mockup
const primary = '#006E33'; // Example color for primary
const secondary = '#F5C77E'; // Example color for secondary
const error = '#D9534F'; // Example color for error
const background = '#F5E9D0'; // Example color for background
const textPrimary = '#324851'; // Example color for primary text

// // Create a theme instance
// const theme = createTheme({
//   palette: {
//     primary: { main: primary },
//     secondary: { main: secondary },
//     error: { main: error },
//     background: { default: background },
//     text: {
//       primary: textPrimary,
//     },
//   },
//   typography: {
//     // Define typography settings here
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//   },
//   // Add more theme customizations if needed
// });
// import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#679688',
      main: '#456856',
      dark: '#234b36',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#a17c60',
      main: '#775d48',
      dark: '#4b3e30',
      contrastText: '#ffffff',
    },
    error: {
      light: '#e57373',
      main: '#d32f2f',
      dark: '#b71c1c',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f5f5f5',
      paper: '#e2d1b3',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  // You can add other theme customizations here
});

// export default theme;


const stripePromise = loadStripe('pk_test_51HyzZVJghAUUh3f0f7kVGfBwYgVvPBPv4sN90DEoN0hlZWg9pFPAlwFxiS4DplB83GnVDvSLgIfBU2F0fEF5luos00BNAXECI6');
const root = ReactDOM.createRoot(document.getElementById('root'));
// const { auth } = useContext(AuthContext);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>

      <AuthProvider>
        {/* <ResponsiveAppBar></ResponsiveAppBar> */}
        <BrowserRouter>
          {/* <Elements stripe={stripePromise}> */}
          <App></App>
          {/* <Routes>
            <Route path="/" element={<App />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/add-camper" element={<CreateCamper />} />
            <Route path="/support" element={<Support />} />
            <Route path="/campers" element={<Campers />} />
            <Route path="/view-camper-details/:camperID" element={<CamperDetails />} />
            <Route path="/create-session" element={<CreateSession />} />
            <Route path="/session-payment/:sessionID" element={<CheckoutForm />} />
            <Route path="/view-session-details/:sessionID" element={<SessionDetails />} />
            </Route>
            </Route>
            <Route path="*" element={<NoPage />} />
          </Routes> */}
          {/* </Elements> */}
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
