import React, { useState } from 'react';
import { Container, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Grid, Typography, IconButton } from '@mui/material';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from '../context/AlertContext';
const CreateCounselor = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        password: '',
        password2: '',
        roles: 'Counselor', // Default to Counselor
    });

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const { setSuccess, setError } = useAlert();

    // const [open, setOpen] = useState(false);
    // const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'success' });

    // const showAlert = (message, severity) => {
    //     setAlertInfo({ message, severity });
    //     setOpen(true);
    // };

    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setOpen(false);
    // };


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate the form data
        // Submit the form data to the server
        try {
            const response = await axiosPrivate.post(`/api/camps/create-user/`, formData);
            console.log(response.data);
            setSuccess("Successfully created user. The user must confirm their account by clicking on a confirmation link in their email. Navigating to counselors page...")
            setTimeout(() => {
                // Navigate after 2 seconds (2000 milliseconds)
                navigate('/counselors');
            }, 2000);
        }
        catch (err) {
            console.error(err);
            setError("Error creating user: " + err.response.data[0].message)

        }
        console.log(formData);
    };

    return (
        <Container maxWidth="sm">

            <Grid container spacing={2} justifyContent="center" sx={{ mt: '80px' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">Create Counselor</Typography>
                </Grid>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth label="First Name" name="first_name" value={formData.first_name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth label="Last Name" name="last_name" value={formData.last_name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth label="Email" name="email" type="email" value={formData.email} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth label="Username" name="username" value={formData.username} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth label="Password" name="password" type="password" value={formData.password} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth label="Confirm Password" name="password2" type="password" value={formData.password2} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Role</FormLabel>
                                <RadioGroup row name="roles" value={formData.roles} onChange={handleChange}>
                                    <FormControlLabel value="Admin" control={<Radio />} label="Admin" />
                                    <FormControlLabel value="Counselor" control={<Radio />} label="Counselor" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>Create Account</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>

            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={alertInfo.severity}
                    sx={{ width: '100%' }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {alertInfo.message}
                </Alert>
            </Snackbar> */}
        </Container>
    );
};

export default CreateCounselor;
