// // src/components/AlertBanner.js
// import React, { useState } from 'react';
// import { useAlert } from '../context/AlertProvider';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
// import CloseIcon from '@mui/icons-material/Close';
// import AddIcon from '@mui/icons-material/Add';
// import { IconButton } from '@mui/material';

// const AlertBanner = () => {
//     const { alert, resetAlert } = useAlert();
//     const [open, setOpen] = useState(false);

//     const handleClose = (event, reason) => {
//         if (reason === 'clickaway') {
//             return;
//         }
//         setOpen(false);
//         resetAlert();
//     };

//     if (!alert.message) return null;

//     return (<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
//         anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
//     >
//         <Alert
//             onClose={handleClose}
//             severity={alert?.type}
//             action={
//                 <IconButton
//                     aria-label="close"
//                     color="inherit"
//                     size="small"
//                     onClick={handleClose}
//                 >
//                     <CloseIcon fontSize="inherit" />
//                 </IconButton>
//             }
//         >
//             {alert?.message}
//         </Alert>
//     </Snackbar>);
// };

// export default AlertBanner;
import React, { useEffect, useState } from 'react';
import { useAlert } from '../context/AlertContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const AlertBanner = () => {
    const { alert, resetAlert } = useAlert();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (alert?.message) {
            setOpen(true); // Open the Snackbar when there's an alert message
        } else {
            setOpen(false); // Close it otherwise
        }
    }, [alert?.message]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        resetAlert();
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity={alert?.type}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {alert?.message}
            </Alert>
        </Snackbar>
    );
};

export default AlertBanner;
