import { Container, Typography, Box } from '@mui/material';
import React from 'react'

export default function NoPage() {
    return (
        <Container main maxWidth="lg">
            <Box sx={{
                margin: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                justifyContent: 'center'
            }}>
                <Box sx={{
                    // margin: '1px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Typography alignText="center" variant="h3">Oops... There's nothing here</Typography>
                </Box>

            </Box>
        </Container>
    )
}
