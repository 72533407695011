import * as React from 'react';
import { useState, useEffect } from 'react';
// import { axiosPrivate } from '../../api/axios'; Uncomment this when using in your project
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { TextField, CircularProgress, Box, Button, IconButton, Container, Typography, Tooltip, Icon } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination
} from '@mui/material';
import AttendanceTable from '../../components/AttendanceTable';
import BusPlanTable from '../../components/BusPlanTable';
import MealPlanTable from '../../components/MealPlanTable';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAlert } from '../../context/AlertContext.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../theme.js'

const Attendance = ({ sessionId }) => {
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [attendanceLogs, setAttendanceLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const [value, setValue] = React.useState('1');
    const { alert, resetAlert, setSuccess } = useAlert();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchLogs = async () => {
        setIsLoading(true);
        try {
            const formattedDate = selectedDate.format('YYYY-MM-DD');
            const response = await axiosPrivate.get(`/api/camps/sessions/attendance-summary/${sessionId}/${formattedDate}`);
            console.log(response.data);
            setAttendanceLogs(response.data);
        } catch (error) {
            console.error('Error fetching attendance logs', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchLogs();
    }, [selectedDate, value, sessionId]);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handlePrevDay = () => {
        setSelectedDate(selectedDate.subtract(1, 'day'));
    };

    const handleNextDay = () => {
        setSelectedDate(selectedDate.add(1, 'day'));
    };

    // Add refresh functionality
    const handleRefreshClick = () => {
        fetchLogs(); // Refetch logs on refresh button click
        setSuccess("Successfully refreshed logs")
    };


    return (
        <Container maxWidth={false} sx={{ mt: 2, maxWidth: isSmallScreen ? 'md' : 'lg' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                        <IconButton onClick={handlePrevDay} aria-label="previous day">
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <DatePicker
                            label="Select Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <IconButton onClick={handleNextDay} aria-label="next day">
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                    <Tooltip title="Refresh Logs">
                        <IconButton onClick={handleRefreshClick}>
                            <RefreshIcon></RefreshIcon>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                                <Tab label="Attendance" value="1" />
                                <Tab label="Bus Plan" value="2" />
                                <Tab label="Meal Plan" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Container>
                                <Typography variant="h6">Attendance Details</Typography>
                                <AttendanceTable campers={attendanceLogs} date={selectedDate.format('YYYY-MM-DD')} isSmallScreen={isSmallScreen}>
                                </AttendanceTable>
                            </Container>
                        </TabPanel>
                        <TabPanel value="2">
                            <Container>
                                <Typography variant="h6">Bus Attendance Details</Typography>
                                <BusPlanTable campers={attendanceLogs.filter(camper => camper.inBusPlan)} isSmallScreen={isSmallScreen}></BusPlanTable>
                            </Container>
                        </TabPanel>
                        <TabPanel value="3">
                            <Container>
                                <Typography variant="h6">Meal Plan Details</Typography>
                                <MealPlanTable campers={attendanceLogs.filter(camper => camper.inMealPlan)} isSmallScreen={isSmallScreen}></MealPlanTable>
                            </Container>
                        </TabPanel>
                    </TabContext>
                </Box>
            </LocalizationProvider>
        </Container>
    );
};

export default Attendance;

