import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Paper, CircularProgress, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export const CheckIns = ({ sessionId }) => {
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(false);
    const [checkIns, setCheckIns] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handlePrevDay = () => {
        setSelectedDate(selectedDate.subtract(1, 'day'));
    };

    const handleNextDay = () => {
        setSelectedDate(selectedDate.add(1, 'day'));
    };

    useEffect(() => {
        const fetchLogs = async () => {
            setIsLoading(true);
            try {
                const formattedDate = selectedDate.format('YYYY-MM-DD');
                const response = await axiosPrivate.get(`/api/camps/sessions/${sessionId}/check-ins-by-date/${formattedDate}`);
                console.log(response.data);
                setCheckIns(response.data || []);
            } catch (error) {
                console.error('Error fetching attendance logs', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchLogs();
    }, [selectedDate, sessionId, axiosPrivate]);

    return (
        <Container maxWidth="lg" sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <IconButton onClick={handlePrevDay} aria-label="previous day">
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <DatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <IconButton onClick={handleNextDay} aria-label="next day">
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            </LocalizationProvider>
            {isLoading ? (
                <CircularProgress />
            ) : (
                checkIns?.length === 0 ? <Typography>No check-ins for this date</Typography> :
                    <Paper elevation={3} style={{ padding: '10px' }}>
                        <Typography variant="h6" gutterBottom>
                            Check-ins for {selectedDate.format('YYYY-MM-DD')}
                        </Typography>
                        <List>
                            {checkIns.map((chkin) => (
                                <ListItem button key={chkin._id} onClick={() => navigate(`/sessions/${sessionId}/check-in-details/${chkin._id}`)}>
                                    <ListItemText primary={chkin.name} secondary={chkin.progress} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
            )}
        </Container>
    );
};
