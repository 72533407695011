import React from 'react';
import { styled } from '@mui/system';
import { Container, Typography, Box, Grid, Card, Button, CardContent, CardMedia } from '@mui/material';

const LandingPage = () => {
    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4, textAlign: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Happy Camper
                </Typography>
                <Box
                    component="img"
                    sx={{
                        height: 233,
                        display: 'inline-block',
                    }}
                    alt="Camping tent"
                    src="./landing_page.jpeg"
                />
                <Typography variant="h4" gutterBottom>
                    Simplifying your summer camp's everyday operations
                </Typography>
                <Button
                    variant="contained"
                    component="a"
                    href="mailto:happycampsolutions@gmail.com?subject=Request for Demo&body=Hi, I would like to request a demo.">
                    Request a Demo
                </Button>
            </Box>
            <Grid container spacing={4}>
                <Grid item xs={12} key={1}>
                    <Card sx={{ width: '100%', height: '100%', display: 'flex-vertical', justifyContent: 'center', alignItems: 'center' }}>
                        <CardMedia
                            component="img"
                            // height="00"
                            image={`/feature${1}.png`}
                            alt={`Feature ${1}`}

                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Manage your camp sessions
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Keep a complete track of different camp sessions throughout the summer. View session schedules, participant lists, and more in one central dashboard.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} key={1}>
                    <Card>
                        <CardMedia
                            component="img"
                            // height="340"
                            // width="150"
                            image={`/feature${2}.png`}
                            alt={`Feature ${2}`}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Track camper attendance
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Monitor every aspect of camp operations from our intuitive dashboard. Track camper check-ins, meal and bus schedules, and every entry and exit to ensure a smooth operation.                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} key={1}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="340"
                            image={`/feature${3}.jpg`}
                            alt={`Feature ${3}`}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Empower counselors with tools to keep campers safe
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Counselors can track the attendance and access important camper information
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LandingPage;

// import * as React from 'react';
// import PropTypes from 'prop-types';

// import CssBaseline from '@mui/material/CssBaseline';
// import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
// import AppAppBar from './components/AppAppBar';
// import Hero from './components/Hero';
// import LogoCollection from './components/LogoCollection';
// import Highlights from './components/Highlights';
// import Pricing from './components/Pricing';
// import Features from './components/Features';
// import Testimonials from './components/Testimonials';
// import FAQ from './components/FAQ';
// import Footer from './components/Footer';
// import getLPTheme from './getLPTheme';

// function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
//     return (
//         <Box
//             sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 width: '100dvw',
//                 position: 'fixed',
//                 bottom: 24,
//             }}
//         >
//             <ToggleButtonGroup
//                 color="primary"
//                 exclusive
//                 value={showCustomTheme}
//                 onChange={toggleCustomTheme}
//                 aria-label="Platform"
//                 sx={{
//                     backgroundColor: 'background.default',
//                     '& .Mui-selected': {
//                         pointerEvents: 'none',
//                     },
//                 }}
//             >
//                 <ToggleButton value>
//                     <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
//                     Custom theme
//                 </ToggleButton>
//                 <ToggleButton value={false}>Material Design 2</ToggleButton>
//             </ToggleButtonGroup>
//         </Box>
//     );
// }

// ToggleCustomTheme.propTypes = {
//     showCustomTheme: PropTypes.shape({
//         valueOf: PropTypes.func.isRequired,
//     }).isRequired,
//     toggleCustomTheme: PropTypes.func.isRequired,
// };

// export default function LandingPage() {
//     const [mode, setMode] = React.useState('light');
//     const [showCustomTheme, setShowCustomTheme] = React.useState(true);
//     const LPtheme = createTheme(getLPTheme(mode));
//     const defaultTheme = createTheme({ palette: { mode } });

//     const toggleColorMode = () => {
//         setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
//     };

//     const toggleCustomTheme = () => {
//         setShowCustomTheme((prev) => !prev);
//     };

//     return (
//         <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
//             <CssBaseline />
//             <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
//             <Hero />
//             <Box sx={{ bgcolor: 'background.default' }}>
//                 <LogoCollection />
//                 <Features />
//                 <Divider />
//                 <Testimonials />
//                 <Divider />
//                 <Highlights />
//                 <Divider />
//                 <Pricing />
//                 <Divider />
//                 <FAQ />
//                 <Divider />
//                 <Footer />
//             </Box>
//             <ToggleCustomTheme
//                 showCustomTheme={showCustomTheme}
//                 toggleCustomTheme={toggleCustomTheme}
//             />
//         </ThemeProvider>
//     );
// }
