import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#679688',
            main: '#456856',
            dark: '#234b36',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#a17c60',
            main: '#775d48',
            dark: '#4b3e30',
            contrastText: '#ffffff',
        },
        error: {
            light: '#e57373',
            main: '#d32f2f',
            dark: '#b71c1c',
            contrastText: '#ffffff',
        },
        background: {
            default: '#f5f5f5',
            paper: '#e2d1b3',
        },
        text: {
            primary: '#333333',
            secondary: '#666666',
        },
    },
    // You can add other theme customizations here
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif', // Example: Change it to your desired font
        button: {
            textTransform: 'none', // Optional: if you want to stop automatic uppercase of button texts
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto, Arial, sans-serif', // Ensures all Typography components use this font
                },
            },
        },
    },
});

export default theme;
