import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useRef, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import { LinearProgress, Alert, MenuItem } from '@mui/material';
import { useAlert } from '../context/AlertContext';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton, List, ListItem, ListItemText
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Happy Camper
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export default function CreateSession() {
    const errRef = useRef();
    const [sessionName, setSessionName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [location, setLocation] = useState("");
    const [timeZone, setTimeZone] = useState('');
    const [csv, setCsv] = useState(null);
    const [errMsg, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const { setSuccess, setError } = useAlert();
    const timeZones = ['America/Montreal', 'America/Toronto', 'America/Vancouver',
        'UTC', 'America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles',
        'Europe/London', 'Europe/Berlin', 'Europe/Paris', 'Asia/Tokyo', 'Asia/Hong_Kong',
        // ... add more as needed
    ];

    const [open, setOpen] = useState(false);
    // other useState hooks remain the same

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            var bodyFormData = new FormData();
            bodyFormData.append("name", sessionName);
            bodyFormData.append("location", location);
            bodyFormData.append("start_date", startDate);
            bodyFormData.append("end_date", endDate);
            bodyFormData.append("file", csv);
            const response = await axiosPrivate({
                method: "post",
                url: `/api/camps/sessions`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true
            }
            );
            console.log(response.data);
            const sessionID = response.data._id;
            setSuccess("Successfully created session!")
            navigate(`/view-session-details/${sessionID}`);
            // navigate('/dashboard')
        }
        catch (err) {
            console.log(err);
            setError(err.response.data[0].message);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* <Alert sx={{ width: ' 100%' }} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} severity="error">{errMsg}</Alert> */}
                {isLoading ? <Box sx={{ width: ' 100%' }}> <LinearProgress /></Box> : <></>}
                <Typography component="h1" variant="h5">
                    Create Session
                </Typography>
                <Typography component="subtitle"></Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="sessionName"
                                label="Session Name"
                                name="sessionName"
                                value={sessionName}
                                onChange={(e) => setSessionName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle">Start Date</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle">End Date</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="startDate"
                                required
                                fullWidth
                                id="startDate"
                                autoFocus
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="endDate"
                                required
                                fullWidth
                                id="endDate"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="location"
                                label="Location"
                                name="location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Time Zone"
                                select
                                name="time_zone"
                                value={timeZone}
                                onChange={(e) => setTimeZone(e.target.value)}
                                fullWidth
                                required
                            >
                                {timeZones.map((zone) => (
                                    <MenuItem key={zone} value={zone}>
                                        {zone}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body">Upload camper list file (optional)</Typography>
                            <input
                                accept=".csv, .xlsx"
                                id="raised-button-file"
                                // multiple
                                type="file"
                                onChange={e => setCsv(e.target.files[0])}
                            />
                            <IconButton onClick={handleClickOpen} size="large">
                                <HelpOutlineIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                    >
                        Create Session
                    </Button>
                </Box>
            </Box>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">File Upload Guide</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        Ensure your Excel or CSV file contains the following fields:
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        Required Fields:
                    </Typography>
                    <List dense={true}>
                        <ListItem><ListItemText primary="First Name (first name, prénom)" /></ListItem>
                        <ListItem><ListItemText primary="Last Name (last name, nom, nom de famille)" /></ListItem>
                        <ListItem><ListItemText primary="Gender (gender, sexe, genre)" /></ListItem>
                        <ListItem><ListItemText primary="Date of Birth (date of birth, dob, date de naissance)" /></ListItem>
                    </List>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        Optional Fields:
                    </Typography>
                    <List dense={true}>
                        <ListItem><ListItemText primary="Group (group, groupe)" /></ListItem>
                        <ListItem><ListItemText primary="Meal Plan (meal plan, plan de repas)" /></ListItem>
                        <ListItem><ListItemText primary="Bus Plan (bus plan, plan de bus)" /></ListItem>
                        <ListItem><ListItemText primary="Health Info (health info, informations de santé)" /></ListItem>
                        <ListItem><ListItemText primary="Special Notes (special notes, notes spéciales)" /></ListItem>
                        <ListItem><ListItemText primary="Guardian First Name (guardian first name, prénom du tuteur)" /></ListItem>
                        <ListItem><ListItemText primary="Guardian Last Name (guardian last name, nom de famille du tuteur)" /></ListItem>
                        <ListItem><ListItemText primary="Guardian Email (guardian email, email du tuteur)" /></ListItem>
                        <ListItem><ListItemText primary="Guardian Phone Number (guardian phone number, numéro de téléphone du tuteur)" /></ListItem>
                    </List>
                    <Typography variant="body2">
                        The first row of your file should include these column headers in English or French as provided.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}