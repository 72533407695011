import React from 'react';
import { styled } from '@mui/system';
import { Typography, Container, Link } from '@mui/material';

const StyledContainer = styled(Container)({
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const Title = styled(Typography)({
    marginBottom: '16px',
});

const SupportPage = () => {
    return (
        <StyledContainer maxWidth="sm" sx={{ mt: 12 }}>
            <div>
                <Title variant="h4" align="center">
                    Support
                </Title>
                <Typography variant="body1" align="center">
                    If you have any questions, issues, or need support with the HappyCamper application, please reach out to us at{' '}
                    <Link href="mailto:support@happy-camper.ca">support@happy-camper.ca</Link>. Our support team will be happy to assist you.
                </Typography>
            </div>
        </StyledContainer>
    );
};

export default SupportPage;
