import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useRef, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Alert from '@mui/material/Alert';
// import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import theme from '../theme';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../context/AlertContext';

export default function CreateCamper() {
    const errRef = useRef();
    const [camper, setCamper] = useState({
        first_name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        nfc_id: "",
        health_info: "",
        special_notes: "",
        guardians: [{ first_name: "", last_name: "", email: "", phone_number: "" }]
    });

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState('');

    const { setSuccess, setError } = useAlert();
    // const [open, setOpen] = useState(false);
    // const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'success' });

    // const showAlert = (message, severity) => {
    //     setAlertInfo({ message, severity });
    //     setOpen(true);
    // };

    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setOpen(false);
    // };


    const handleChange = (event) => {
        setCamper({ ...camper, [event.target.name]: event.target.value });
    };

    const handleGenderChange = (event) => {
        setCamper({ ...camper, gender: event.target.value });
    };

    const handleGuardianChange = (index, event) => {
        const updatedGuardians = [...camper.guardians];
        updatedGuardians[index] = { ...updatedGuardians[index], [event.target.name]: event.target.value };
        setCamper({ ...camper, guardians: updatedGuardians });
    };

    const handleAddGuardian = () => {
        setCamper({ ...camper, guardians: [...camper.guardians, { first_name: "", last_name: "", email: "", phone_number: "" }] });
    };

    const handleRemoveGuardian = (index) => {
        const updatedGuardians = camper.guardians.filter((_, i) => i !== index);
        setCamper({ ...camper, guardians: updatedGuardians });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log(camper);
            const response = await axiosPrivate.post("/api/camps/campers", JSON.stringify(camper));
            console.log(response.data);
            setSuccess("Successfully created camper! Redirecting to campers page...");
            setTimeout(() => {
                navigate('/campers');
            }, 1500)
        } catch (err) {
            // setErrMsg(err.response.data.message);
            console.log(err.response.data);
            setError(err.response.data.message);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Alert sx={{ width: ' 100%' }} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} severity="error">{errMsg}</Alert>
                <Typography component="h1" variant="h5">
                    Create Camper
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="first_name"
                                value={camper.first_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="last_name"
                                value={camper.last_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle">Date of Birth</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="date_of_birth"
                                required
                                fullWidth
                                id="dateOfBirth"
                                autoFocus
                                type="date"
                                value={camper.date_of_birth}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Female"
                                    value={camper.gender}
                                    name="gender"
                                    row
                                    onChange={handleGenderChange}
                                >
                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="nfc_id"
                                label="NFC ID"
                                value={camper.nfc_id}
                                onChange={handleChange}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="health_info"
                                label="Health Info"
                                value={camper.health_info}
                                onChange={handleChange}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="special_notes"
                                label="Special Notes"
                                value={camper.special_notes}
                                onChange={handleChange}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Guardians</Typography>
                            {camper.guardians.map((guardian, index) => (
                                <Box key={index} sx={{ mt: 3, mb: 3 }}>
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <TextField
                                            name="first_name"
                                            label="First Name"
                                            value={guardian.first_name}
                                            onChange={(e) => handleGuardianChange(index, e)}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <TextField
                                            name="last_name"
                                            label="Last Name"
                                            value={guardian.last_name}
                                            onChange={(e) => handleGuardianChange(index, e)}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <TextField
                                            name="email"
                                            label="Email"
                                            value={guardian.email}
                                            onChange={(e) => handleGuardianChange(index, e)}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <TextField
                                            name="phone_number"
                                            label="Phone Number"
                                            value={guardian.phone_number}
                                            onChange={(e) => handleGuardianChange(index, e)}
                                            fullWidth
                                        />
                                    </Box>
                                    {/* <Button onClick={() => handleRemoveGuardian(index)}>Remove Guardian</Button> */}
                                    <Tooltip title="Remove Guardian">
                                        <IconButton onClick={() => handleRemoveGuardian(index)}>
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            ))}
                            {/* <Button onClick={handleAddGuardian}>Add Guardian</Button> */}
                            <Tooltip title="Add Guardian">

                                <IconButton onClick={handleAddGuardian}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                    >
                        Create Camper
                    </Button>
                </Box>
            </Box>
            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={alertInfo.severity}
                    sx={{ width: '100%' }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {alertInfo.message}
                </Alert>
            </Snackbar> */}
        </Container>
    );
}