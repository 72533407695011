import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
// import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../api/axios';
import { MenuItem } from '@mui/material';
import { useAlert } from '../context/AlertContext';
import LinearProgress from '@mui/material/LinearProgress';
import { InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Happy Camper
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/api/camps/register';

export default function SignUp() {

    const firstNameRef = useRef();
    const userRef = useRef();
    const errRef = useRef();

    const [firstName, setFirstName] = useState('');
    const [firstNameFocus, setFirstNameFocus] = useState(false);

    const [lastName, setLastName] = useState('');
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [campName, setCampName] = useState('');
    const [campNameFocus, setCampNameFocus] = useState(false);

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [streetName, setStreetName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [timeZone, setTimeZone] = useState('');

    const [email, setEmail] = useState('');

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    // const [errMsg, setErrMsg] = useState('');
    const [successfulRegistration, setSuccessfulRegistration] = useState(false);
    const { setSuccess, setError } = useAlert();
    const [isLoading, setIsLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);


    // Manually defined list of time zones
    const timeZones = ['America/Montreal', 'America/Toronto', 'America/Vancouver'];

    useEffect(() => {
        firstNameRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setError('');
    }, [user, pwd, matchPwd])


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setError("Invalid Entry");
            return;
        }
        try {
            setIsLoading(true);
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({
                    "camp_manager": {
                        first_name: firstName,
                        last_name: lastName
                    },
                    name: campName,
                    "address": {
                        street: streetName,
                        city: city,
                        state: state,
                        postal_code: postalCode,
                        country: country,
                    },
                    "time_zone": timeZone,
                    username: user,
                    email: email,
                    password: pwd,
                    password2: matchPwd,
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            console.log(response?.data);
            console.log(response?.accessToken);
            console.log(JSON.stringify(response))
            setIsLoading(false);
            setSuccessfulRegistration(true);
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setUser('');
            setPwd('');
            setMatchPwd('');
        } catch (err) {
            setIsLoading(false);
            if (!err?.response) {
                setError('No Server Response');
            } else if (err.response?.status === 409) {
                setError(err.response?.data?.message);
            } else {
                setError('Registration Failed')
            }
            errRef.current.focus();
        }
    };

    return (
        <>
            {successfulRegistration ? (<Container component="main" maxWidth="xs">
                <Typography variant="h6">You have successfully registered! Please check your email address to verify your account.</Typography>
                <Button as={Link} to='/sign-in'> Sign In</Button>
            </Container>) : (
                <Container component="main" maxWidth="xs">
                    {isLoading ?
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                        : <></>
                    }
                    <CssBaseline />
                    {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                        <Typography variant="h6" sx={{ mt: 3 }}>Camp Manager</Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        ref={firstNameRef}
                                        autoFocus
                                        onChange={(e) => setFirstName(e.target.value)}
                                        value={firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="family-name"
                                        onChange={(e) => setLastName(e.target.value)}
                                        value={lastName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        name="username"
                                        autoComplete="username"
                                        ref={userRef}
                                        onChange={(e) => setUser(e.target.value)}
                                        value={user}
                                        onFocus={() => setUserFocus(true)}
                                        onBlur={() => setUserFocus(false)}
                                    />
                                </Grid>
                                <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                    4 to 24 characters.<br />
                                    Must begin with a letter.<br />
                                    Letters, numbers, underscores, hyphens allowed.
                                </p>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        autoComplete="new-password"
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}
                                        onFocus={() => setPwdFocus(true)}
                                        onBlur={() => setPwdFocus(false)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                    8 to 24 characters.<br />
                                    Must include uppercase and lowercase letters, a number and a special character.<br />
                                    Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                </p>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Repeat Password"
                                        type={showRepeatPassword ? "text" : "password"}
                                        id="password2"
                                        autoComplete="new-password"
                                        onChange={(e) => setMatchPwd(e.target.value)}
                                        value={matchPwd}
                                        onFocus={() => setMatchFocus(true)}
                                        onBlur={() => setMatchFocus(false)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle repeat password visibility"
                                                        onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                                        edge="end"
                                                    >
                                                        {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                    {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                                    Must match the first password input field.
                                </p>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }} align="center">Camp Information</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="campName"
                                        label="Camp Name"
                                        name="campName"
                                        onChange={(e) => setCampName(e.target.value)}
                                        value={campName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="streetAddress"
                                        label="Street Address"
                                        name="streetAddress"
                                        onChange={(e) => setStreetName(e.target.value)}
                                        value={streetName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="city"
                                        label="City"
                                        name="city"
                                        onChange={(e) => setCity(e.target.value)}
                                        value={city}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="state"
                                        label="State/province"
                                        name="state"
                                        onChange={(e) => setState(e.target.value)}
                                        value={state}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="postalCode"
                                        label="Postal code"
                                        name="postalCode"
                                        onChange={(e) => setPostalCode(e.target.value)}
                                        value={postalCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="country"
                                        label="Country"
                                        name="country"
                                        onChange={(e) => setCountry(e.target.value)}
                                        value={country}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Time Zone"
                                        select
                                        name="time_zone"
                                        value={timeZone}
                                        onChange={(e) => setTimeZone(e.target.value)}
                                        fullWidth
                                        required
                                    >
                                        {timeZones.map((zone) => (
                                            <MenuItem key={zone} value={zone}>
                                                {zone}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    disabled={!validName || !validPwd || !validMatch ? true : false}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign Up
                                </Button>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link as={Link} to='/sign-in' href="#" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 5 }} />
                </Container>
            )}
        </>
    );
}