import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Alert } from '@mui/material';
import axios from '../api/axios';
import { useAlert } from '../context/AlertContext';
export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { setSuccess } = useAlert();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setMessage('');

        // Here you would send a request to your backend
        try {
            const response = await axios.post('/api/camps/reset-password', { email });
            // setMessage(response.data.message);
            console.log('Email sent to:', email); // Replace with actual request
            setMessage('If an account with that email exists, we have sent a link to reset your password.');
        } catch (err) {
            setError('Failed to send password reset email.');
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 4 }}>
            <Typography component="h1" variant="h5">
                Forgot Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Send Reset Link
                </Button>
            </form>
            {message && <Alert severity="info" sx={{ mt: 2 }}>{message}</Alert>}
            {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        </Container>
    );
}
