import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, IconButton, Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Container, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import theme from '../theme';
import { Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



const textFieldHeight = 56; // Adjust based on your TextField size
const containedIconButtonStyle = {
    backgroundColor: theme.palette.primary.main, // Theme primary color
    color: theme.palette.primary.contrastText, // Contrast text color
    height: textFieldHeight,
    width: textFieldHeight,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark, // Darker shade on hover
    },
};

export default function Dashboard() {
    const [dashboard, setDashboard] = useState();
    const [sessions, setSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const sessionsFetched = useRef(false);

    const [openDialog, setOpenDialog] = useState(false);
    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleRemoveSelectedSessions = async () => {
        try {
            console.log(selectedSessions);
            const response = await axiosPrivate.post('/api/camps/sessions/delete', { "sessionIds": selectedSessions });
            // Update the UI accordingly
            // Assuming the deletion was successful, filter out the deleted sessions
            if (response.status === 200) { // or check for a specific response indicating success
                setSessions(prevSessions => prevSessions.filter(session => !selectedSessions.includes(session._id)));
            }

            handleDialogClose();
        } catch (err) {
            console.error(err);
        }
    }

    const [selectedSessions, setSelectedSessions] = useState([]);

    const handleSelectSession = (event, id) => {
        const selectedIndex = selectedSessions.indexOf(id);
        let newSelectedSessions = [];

        if (selectedIndex === -1) {
            newSelectedSessions = newSelectedSessions.concat(selectedSessions, id);
        } else if (selectedIndex >= 0) {
            newSelectedSessions = newSelectedSessions.concat(
                selectedSessions.slice(0, selectedIndex),
                selectedSessions.slice(selectedIndex + 1)
            );
        }

        setSelectedSessions(newSelectedSessions);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = sessions.map((n) => n._id);
            setSelectedSessions(newSelecteds);
            return;
        }
        setSelectedSessions([]);
    };

    const isAllSelected = sessions.length > 0 && selectedSessions.length === sessions.length;

    const isSelected = (id) => selectedSessions.indexOf(id) !== -1;


    function BasicTable() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isAllSelected}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ 'aria-label': 'Select all sessions' }}
                                />
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Session Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Start Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>End Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Location</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Number of Campers</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">Session Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessions?.map((row, index) => {

                            const isItemSelected = isSelected(row._id);

                            return (<TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={row._id}
                                selected={isItemSelected}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={isItemSelected}
                                        onChange={(event) => handleSelectSession(event, row._id)}
                                        inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row" >
                                    {row.name}
                                </TableCell>
                                <TableCell>{row?.start_date.split('T')[0]}</TableCell>
                                <TableCell>{row?.end_date.split('T')[0]}</TableCell>
                                <TableCell>{row?.location}</TableCell>
                                <TableCell >{row.campers?.length}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title="View & Edit Session">
                                        <IconButton
                                            // variant="contained"
                                            onClick={() => navigate(`/view-session-details/${row._id}`)}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>)
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    useEffect(() => {

        const getDashboard = async () => {
            try {
                const response = await axiosPrivate.get('/api/camps/dashboard');
                setDashboard(response.data.campOrganization);
                setSessions(response.data.sessions)
                // response.data?.sessions.forEach(async (session) => {
                //     const response = await axiosPrivate.get(`/api/camps/sessions/${session}`);
                //     console.log(response.data);
                //     setSessions((prevState) => [...prevState, response.data]);
                // })

            } catch (err) {
                console.error(err);
                navigate('/sign-in', { state: { from: location }, replace: true });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (sessionsFetched.current === false) {
            sessionsFetched.current = true;
            getDashboard();
            sessions.sort((a, b) => {
                console.log('firing here');
                console.log(new Date(a.start_date) - new Date(b.start_date));
                return (new Date(a.start_date) - new Date(b.start_date));
            });

        }

        return () => {
            // setDashboard();
            // setSessions([]);
            // setIsLoading(true);
        }

    }, [axiosPrivate, navigate, location])



    return (
        isLoading ? <Box style={{ textAlign: 'center', marginTop: '10px' }}>
            < CircularProgress />
        </Box > :
            <Container component="main" maxWidth="md" sx={{ mt: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <h1 style={{ textAlign: "center" }}>Welcome, {dashboard?.name}</h1>
                    </Grid>
                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box
                            sx={{
                                margin: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyItems: 'center',
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                // justifyItems: 'center',
                            }}>
                                <Tooltip title="Create Session">
                                    <IconButton
                                        variant="contained"
                                        onClick={() => { navigate('/create-session') }}
                                        style={containedIconButtonStyle}

                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Sessions">
                                    <IconButton
                                        variant="contained"
                                        onClick={handleDialogOpen}
                                        style={{
                                            ...containedIconButtonStyle,
                                            marginLeft: 8
                                        }}
                                        disabled={selectedSessions.length === 0}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>

                    </Grid>
                    {sessions?.length > 0 ?
                        <Grid item xs={12}>
                            <BasicTable></BasicTable>
                        </Grid>
                        : <Grid item xs={12}>
                            <Typography variant="h6">Create your first camp session to effortlessly manage your summer camp!</Typography>
                        </Grid>
                    }
                </Grid >
                <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the selected sessions?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={handleRemoveSelectedSessions} autoFocus>
                            Yes, Delete
                        </Button>
                    </DialogActions>
                </Dialog>

            </Container>


    )
}
