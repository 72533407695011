import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../api/axios';


export default function ResetPassword() {
    const params = useParams();
    const token = params.token; // Assuming the token is passed as a URL query parameter

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');
        setError('');

        if (newPassword !== confirmPassword) {
            setError("Passwords don't match.");
            return;
        }

        // Here you would send a request to your backend
        try {
            const response = await axios.post(`/api/camps/reset-password/${token}`, { password: newPassword, password2: confirmPassword });
            // console.log(response.data); // Handle the response appropriately
            setMessage('Your password has been reset successfully.');
            setTimeout(() => navigate('/sign-in'), 2000); // Redirect to login after a delay
        } catch (err) {
            setError('Failed to reset password.');
            console.error(err);
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 2 }}>
            <Typography component="h1" variant="h5">
                Reset Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="newPassword"
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm New Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Reset Password
                </Button>
            </form>
            {message && <Alert severity="success" sx={{ mt: 2 }} >{message}</Alert>}
            {error && <Alert severity="error" sx={{ mt: 2 }} >{error}</Alert>}
        </Container>
    );
}
