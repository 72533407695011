// src/contexts/AlertContext.js
import React, { createContext, useState, useContext } from 'react';

const AlertContext = createContext({});

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ message: '', type: '' });

    const setSuccess = message => {
        setAlert({ message, type: 'success' });
    };

    const setError = message => {
        setAlert({ message, type: 'error' });
    };

    const resetAlert = () => {
        setAlert({ message: '', type: '' });
    };

    return (
        <AlertContext.Provider value={{ alert, setSuccess, setError, resetAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
