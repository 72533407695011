// import React, { useState, useEffect } from 'react';
// import useAxiosPrivate from '../hooks/useAxiosPrivate';
// import { useNavigate, useParams } from 'react-router-dom';
// import { Grid, Box, CircularProgress, Container, Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

// export default function CounselorDetails() {
//     const axiosPrivate = useAxiosPrivate();
//     const [counselorDetails, setCounselorDetails] = useState(null);
//     const [isLoading, setIsLoading] = useState(true);
//     const navigate = useNavigate();
//     const params = useParams();

//     useEffect(() => {
//         const getCounselorDetails = async () => {
//             try {
//                 const response = await axiosPrivate.get(`/api/camps/get-user/${params.counselorId}`);
//                 console.log(response.data);
//                 setCounselorDetails(response.data);
//             } catch (err) {
//                 console.error(err);
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         getCounselorDetails();
//         return () => {
//             setCounselorDetails(null);
//         };
//     }, [axiosPrivate, params.counselorId]);

//     const renderSessionsTable = () => {
//         return (
//             <TableContainer component={Paper}>
//                 <Table sx={{ minWidth: 650 }} aria-label="simple table">
//                     <TableHead>
//                         <TableRow>
//                             <TableCell style={{ fontWeight: 'bold' }}>Session Name</TableCell>
//                             <TableCell style={{ fontWeight: 'bold' }}>Details</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {counselorDetails?.sessions.map((session, index) => (
//                             <TableRow key={index}>
//                                 <TableCell>{session.name}</TableCell>
//                                 <TableCell>
//                                     <Button variant="contained" onClick={() => navigate(`/view-session-details/${session._id}`)}>View Session</Button>
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         );
//     };

//     return (
//         isLoading ? <CircularProgress /> :
//             <Container component="main" maxWidth="md" sx={{ mt: 10 }}>
//                 {/* <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}> */}
//                 <Grid container spacing={2} justifyContent="space-between" justifyItems="space-between">
//                     <Grid item xs={12}>
//                         <Typography variant="h4">Counselor: {counselorDetails?.first_name} {counselorDetails?.last_name}</Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Box sx={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             justifyContent: "space-between"
//                         }}>
//                             <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Username:</Typography>
//                             <Typography variant="subtitle1">{counselorDetails?.username}</Typography>
//                         </Box>
//                     </Grid>
//                     <Grid item sx={12}>
//                         <Box sx={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             justifyContent: "space-between"
//                         }}>
//                             <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Email:</Typography>
//                             <Typography variant="subtitle1">{counselorDetails?.email}</Typography>
//                         </Box>
//                     </Grid>

//                     <Grid item xs={12}>
//                         <Box sx={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             justifyContent: "space-between"
//                         }}>
//                             <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Camp ID:</Typography>
//                             <Typography variant="subtitle1">{counselorDetails?.camp_id}</Typography>
//                         </Box>
//                     </Grid>

//                     <Grid item xs={12}>
//                         <Box sx={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             justifyContent: "space-between"
//                         }}>
//                             <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Status: </Typography>
//                             <Typography variant="subtitle1">{counselorDetails?.status}</Typography>

//                         </Box>
//                     </Grid>

//                     <Grid item xs={12}>
//                         <Box sx={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             justifyContent: "space-between"
//                         }}>
//                             <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Created Date:</Typography>
//                             <Typography variant="subtitle1">{new Date(counselorDetails?.Created_date).toLocaleDateString()}</Typography>
//                         </Box>
//                     </Grid>

//                     <Grid item xs={12}>
//                         <Box sx={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             justifyContent: "space-between"
//                         }}>
//                             <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Role: </Typography>
//                             <Typography variant="subtitle1">{Object.keys(counselorDetails?.roles)}</Typography>

//                         </Box>
//                     </Grid>
//                 </Grid>

//                 <Box sx={{ mt: 2 }}>
//                     <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//                         Assigned Sessions
//                     </Typography>
//                     {counselorDetails?.sessions.length > 0 ? renderSessionsTable() : <Typography>No assigned sessions.</Typography>}
//                 </Box>

//                 {/* Additional components for actions like reset password, delete counselor, etc. */}
//                 {/* </Box> */}
//             </Container>
//     );
// }

import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Grid, Box, CircularProgress, Container, Typography, Button, TableContainer, Paper, Table,
    TableHead, TableRow, TableCell, TableBody, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';

export default function CounselorDetails() {
    const axiosPrivate = useAxiosPrivate();
    const [counselorDetails, setCounselorDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const getCounselorDetails = async () => {
            try {
                const response = await axiosPrivate.get(`/api/camps/get-user/${params.counselorId}`);
                console.log('Counselor details fetched:', response.data);
                setCounselorDetails(response.data);
            } catch (err) {
                console.error('Error fetching counselor details:', err);
            } finally {
                setIsLoading(false);
            }
        };

        getCounselorDetails();
        return () => {
            setCounselorDetails(null);
        };
    }, [axiosPrivate, params.counselorId]);

    const handleRoleChange = async (event) => {
        const newRole = event.target.value;
        try {
            const response = await axiosPrivate.patch(`/api/camps/update-user-role/${params.counselorId}`, { role: newRole });
            console.log('Role update response:', response.data);
            setCounselorDetails(prevDetails => ({
                ...prevDetails,
                roles: { ...prevDetails.roles, [newRole]: true }
            }));
        } catch (err) {
            console.error('Error updating role:', err);
        }
    };

    const renderSessionsTable = () => {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Session Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {counselorDetails?.sessions.map((session, index) => (
                            <TableRow key={index}>
                                <TableCell>{session.name}</TableCell>
                                <TableCell>
                                    <Button variant="contained" onClick={() => navigate(`/view-session-details/${session._id}`)}>View Session</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    console.log("Counselor Details on Render:", counselorDetails);

    const currentRole = counselorDetails ? Object.keys(counselorDetails.roles)[0] : '';

    return (
        isLoading ? <CircularProgress /> :
            <Container component="main" maxWidth="md" sx={{ mt: 10 }}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12}>
                        <Typography variant="h4">Counselor: {counselorDetails?.first_name} {counselorDetails?.last_name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {['username', 'email', 'camp_id', 'status', 'Created_date'].map((field) => (
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", mb: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{field.replace('_', ' ')}:</Typography>
                                <Typography variant="subtitle1">{counselorDetails?.[field]}</Typography>
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Role: </Typography>
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="role-select-label">Role</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={currentRole}
                                    onChange={handleRoleChange}
                                    label="Role"
                                >
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="counselor">Counselor</MenuItem>
                                    <MenuItem value="viewer">Viewer</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Assigned Sessions
                    </Typography>
                    {counselorDetails?.sessions.length > 0 ? renderSessionsTable() : <Typography>No assigned sessions.</Typography>}
                </Box>
            </Container>
    );
}

