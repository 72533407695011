import { useState, useEffect, useContext } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Container, Typography, Checkbox, TablePagination, TextField, IconButton, Tooltip } from '@mui/material';
import { LinearProgress } from '@mui/material';
import CustomPaginationActionsTableCounselors from '../components/CustomPaginationActionsTableCounselors';
import AuthContext from '../context/AuthProvider';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import theme from '../theme.js'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../context/AlertContext.js';
import Fuse from 'fuse.js'
const AddCounselorDialog = ({ open, onClose, counselors, onAddSelectedCounselors }) => {
    const [selectedCounselors, setSelectedCounselors] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleToggleCounselor = (counselorId) => {
        const currentIndex = selectedCounselors.indexOf(counselorId);
        const newChecked = [...selectedCounselors];

        if (currentIndex === -1) {
            newChecked.push(counselorId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedCounselors(newChecked);
    };

    const handleAddCounselors = () => {
        onAddSelectedCounselors(selectedCounselors);
        // onClose(); // Close the dialog
    };

    const handleSelectCounselor = (event, counselor) => {
        const selectedIndex = selectedCounselors.indexOf(counselor);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCounselors, counselor);
        } else if (selectedIndex >= 0) {
            newSelected = newSelected.concat(selectedCounselors.slice(0, selectedIndex), selectedCounselors.slice(selectedIndex + 1));
        }

        setSelectedCounselors(newSelected);
    };

    const isSelected = (counselor) => selectedCounselors.indexOf(counselor) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = counselors.map((counselor) => counselor._id);
            setSelectedCounselors(newSelecteds);
            return;
        }
        setSelectedCounselors([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const visibleCounselors = counselors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <DialogTitle>Add Counselors</DialogTitle>
            <DialogContent>
                {visibleCounselors?.length > 0 ?
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selectedCounselors.length > 0 && selectedCounselors.length < counselors.length}
                                            checked={counselors.length > 0 && selectedCounselors.length === counselors.length}
                                            onChange={handleSelectAllClick}
                                        />
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Role</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visibleCounselors.map((counselor) => (
                                    <TableRow key={counselor._id}
                                        selected={isSelected(counselor._id)}
                                        onClick={(event) => handleSelectCounselor(event, counselor._id)}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(counselor._id)}
                                            />
                                        </TableCell>
                                        <TableCell>{counselor.first_name}</TableCell>
                                        <TableCell>{counselor.last_name}</TableCell>
                                        <TableCell>{Object.keys(counselor?.roles)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={counselors.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                    : <Typography variant="h6">No matching counselors</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddCounselors}>Add Selected</Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const SessionCounselorsTable = ({ title, counselors, availableCounselors, onAddCounselor, onRemoveCounselors }) => {
    const [selectedCounselors, setSelectedCounselors] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddCounselorDialogOpen, setIsAddCounselorDialogOpen] = useState(false);

    const { alert, resetAlert, setSuccess } = useAlert();

    // Fuse.js configuration
    const fuseOptions = {
        keys: ["first_name", "last_name"],
        threshold: 0.4,
    };

    // Initialize Fuse with the counselors array
    const fuse = new Fuse(counselors, fuseOptions);

    // Filtering logic using Fuse.js
    const filteredCounselors = searchQuery.length > 0
        ? fuse.search(searchQuery).map(result => result.item)
        : counselors;

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0); // Reset to the first page when search changes
    };

    const handleOpenAddCounselorDialog = () => {
        setIsAddCounselorDialogOpen(true);
    };

    const handleCloseAddCounselorDialog = () => {
        setIsAddCounselorDialogOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddSelectedCounselors = (selectedCounselorIds) => {
        onAddCounselor(selectedCounselorIds);
        setIsAddCounselorDialogOpen(false);
        setSuccess("Successfully added selected counselors");
    };

    const handleRemoveSelectedCounselors = () => {
        onRemoveCounselors(selectedCounselors);
        setSuccess("Successfully removed selected counselors")
    }


    const handleSelectCounselor = (event, counselorId) => {
        const selectedIndex = selectedCounselors.indexOf(counselorId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedCounselors, counselorId];
        } else {
            newSelected = newSelected.concat(
                selectedCounselors.slice(0, selectedIndex),
                selectedCounselors.slice(selectedIndex + 1)
            );
        }

        setSelectedCounselors(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = counselors.map((counselor) => counselor._id);
            setSelectedCounselors(newSelecteds);
            return;
        }
        setSelectedCounselors([]);
    };


    const isSelected = (counselorId) => selectedCounselors.includes(counselorId);


    const viewCounselorDetails = (counselorId) => {
        navigate(`/view-counselor-details/${counselorId}`);
    };

    // const filteredCounselors = counselors?.filter(counselor =>
    //     counselor?.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     counselor?.last_name.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    const visibleCounselors = filteredCounselors?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const textFieldHeight = 56; // Adjust based on your TextField size
    const containedIconButtonStyle = {
        backgroundColor: theme.palette.primary.main, // Theme primary color
        color: theme.palette.primary.contrastText, // Contrast text color
        height: textFieldHeight,
        width: textFieldHeight,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Darker shade on hover
        },
    };
    return (
        <Container>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginBottom: '20px' }}>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Typography variant="h6" >{title}</Typography>
                    </Box>
                </Grid>
                {counselors?.length > 0 ?
                    <Grid item xs={10} >
                        <TextField
                            variant="outlined"
                            placeholder="Search by name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            style={{ marginBottom: 20, width: "100%" }}
                            size="medium"
                            fullWidth
                        // sx={{
                        //     marginBottom: "20px",
                        //     '& .MuiInputBase-input': {
                        //         height: '40px', // Adjust input height as needed
                        //         padding: '10px', // Adjust padding to vertically center the text
                        //     },
                        // }}
                        />
                    </Grid>
                    : <Grid item xs={10}> </Grid>}
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box display="flex" gap="10px">
                        <Tooltip title="Add Counselors">
                            <IconButton variant="contained" onClick={handleOpenAddCounselorDialog} style={containedIconButtonStyle}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        {counselors.length > 0 ? <Tooltip title="Remove Counselors">
                            <IconButton variant="contained" onClick={handleRemoveSelectedCounselors} style={containedIconButtonStyle} disabled={selectedCounselors.length === 0}> <RemoveIcon />
                            </IconButton>
                        </Tooltip> : <></>}
                        <AddCounselorDialog
                            open={isAddCounselorDialogOpen}
                            onClose={handleCloseAddCounselorDialog}
                            counselors={availableCounselors}
                            onAddSelectedCounselors={handleAddSelectedCounselors}
                        />
                    </Box>
                </Grid>
                {visibleCounselors.length > 0 ?
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={selectedCounselors.length > 0 && selectedCounselors.length < counselors.length}
                                                checked={counselors.length > 0 && selectedCounselors.length === counselors.length}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Role</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {visibleCounselors.map((counselor) => (
                                        <TableRow
                                            key={counselor._id}
                                            selected={isSelected(counselor._id)}
                                            onClick={(event) => handleSelectCounselor(event, counselor._id)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={isSelected(counselor._id)}
                                                    onChange={(event) => handleSelectCounselor(event, counselor._id)}
                                                />
                                            </TableCell>
                                            <TableCell>{counselor?.first_name}</TableCell>
                                            <TableCell>{counselor?.last_name}</TableCell>
                                            <TableCell>{Object.keys(counselor?.roles)}</TableCell>
                                            <TableCell>
                                                <Button onClick={() => viewCounselorDetails(counselor._id)}>View Details</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={counselors.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Grid>
                    :
                    <Typography variant="h6">No counselors found</Typography>
                }
            </Grid>
        </Container>
    );
};

export default function Counselors({ counselors, availableCounselors, onAddCounselor, onRemoveCounselors, isLoading }) {

    return (
        isLoading ? <LinearProgress></LinearProgress> :
            <Container component="main">
                < SessionCounselorsTable title="Assigned Counselors" counselors={counselors} availableCounselors={availableCounselors} onAddCounselor={onAddCounselor} onRemoveCounselors={onRemoveCounselors} > </SessionCounselorsTable >
            </Container >)
};