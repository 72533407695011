import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Container, CssBaseline, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAlert } from '../context/AlertContext';
const phoneRegex = /^\+?[1-9]\d{1,14}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function validatePhoneNumber(phone) {
    return phoneRegex.test(phone);
}

function validateEmail(email) {
    return emailRegex.test(email);
}


export default function CamperDetails() {
    const axiosPrivate = useAxiosPrivate();
    const [camperDetails, setCamperDetails] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [toBeDeleted, setToBeDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { setSuccess, setError } = useAlert();

    const sessionsFetched = useRef(false);

    function BasicTable() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            <TableCell style={{ fontWeight: 'bold' }}>Session Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessions?.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell >{row.name}</TableCell>
                                <TableCell as={Button} variant="contained" onClick={() => navigate(`/view-session-details/${row._id}`)} >View Session</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    function GuardiansTable() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="guardians table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Phone Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {camperDetails?.guardians.map((guardian, index) => (
                            <TableRow key={index}>
                                <TableCell>{guardian.first_name}</TableCell>
                                <TableCell>{guardian.last_name}</TableCell>
                                <TableCell>{guardian.email} </TableCell>
                                <TableCell>{guardian.phone_number}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }


    function DeleteDialog() {
        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleDelete = async () => {
            setOpen(false);
            try {
                console.log(camperDetails)
                const response = await axiosPrivate.delete(`/api/camps/campers/${camperDetails?._id}`);
                console.log(response.data);
                setSuccess("Successfully deleted camper")
            } catch (err) {
                console.error(err);
                // Optionally, handle the error more gracefully here
            } finally {
                navigate('/campers');
            }
        }


        return (
            <div>
                <Button style={{ margin: '2px' }} variant="outlined" onClick={handleClickOpen} startIcon={<DeleteIcon />}>
                    Delete Camper
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete Camper?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this camper? Deleting the camper will remove them from all sessions and the camper directory.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete}>Yes</Button>
                        <Button onClick={handleClose} autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    useEffect(() => {

        const getCamperDetails = async () => {
            try {
                const url = `/api/camps/campers/${location?.pathname.split("/")[2]}`;
                const response = await axiosPrivate.get(url);
                console.log(response.data);
                setCamperDetails(response.data);
                for (const session of response.data?.sessions) {
                    const sessionsResponse = await axiosPrivate.get(`/api/camps/sessions/${session}`);
                    console.log(sessionsResponse.data);
                    setSessions((prevState) => [...prevState, sessionsResponse.data])
                }
            } catch (err) {
                console.error(err);
            }
            finally {
                setIsLoading(false);
            }
        }



        if (sessionsFetched.current === false) {
            sessionsFetched.current = true;
            getCamperDetails();
        }
        return () => {
            setCamperDetails(null);
            setSessions([]);
        }
    }, [])


    return (
        isLoading ? <Box style={{ textAlign: 'center' }}>
            <CircularProgress />
        </Box> :
            <Container component="main" maxWidth="md" sx={{ mt: 10 }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'

                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography variant="h4">{camperDetails?.first_name + " " + camperDetails?.last_name}</Typography>
                        {/* <Button variant="contained" onClick={() => { navigate(`/edit-camper/`) }}>Edit Camper</Button> */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",

                        }}>
                            <Button style={{ margin: '2px' }} variant="contained" onClick={() => { navigate(`/edit-camper/${camperDetails?._id}`) }}>Edit Camper</Button>
                            <DeleteDialog></DeleteDialog>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle">Date of Birth</Typography>
                        <Typography variant="subtitle">{camperDetails?.date_of_birth.split("T")[0]}</Typography>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle">Gender</Typography>
                        <Typography variant="subtitle">{camperDetails?.gender}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "bold" }}>NFC ID</Typography>
                        <Typography>{camperDetails?.nfc_id}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "bold" }}>Health Info</Typography>
                        <Typography>{camperDetails?.health_info}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "bold" }}>Special Notes</Typography>
                        <Typography>{camperDetails?.special_notes}</Typography>
                    </Box>

                    {/* Display Guardians */}
                    <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                        Guardians
                    </Typography>
                    {camperDetails?.guardians && camperDetails.guardians.length > 0 ? (
                        <GuardiansTable />
                    ) : (
                        <Typography>No guardian information available.</Typography>
                    )}

                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Registered Sessions
                    </Typography>
                    {sessions?.length > 0 ?
                        <BasicTable></BasicTable>
                        :
                        <Typography>Camper is not registered in any session.</Typography>
                    }

                </Box>
            </Container>
    )
}
