import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();


    return (
        auth?.accessToken
            ? <Outlet />
            : <Navigate to="/sign-in" state={{ from: location }} replace />
    )
}

export default RequireAuth;
