import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { CircularProgress, Container, Typography } from '@mui/material';

const EmailVerificationPage = () => {
    const { token } = useParams(); // Assuming the URL contains a parameter named 'token'
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        if (token) {
            verifyEmail(token);
        } else {
            navigate('/'); // Redirect to home if no token is present
        }
    }, [token, navigate]);

    const verifyEmail = async (verificationToken) => {
        try {
            const response = await axiosPrivate.post(`/api/camps/confirm-email/${verificationToken}`);
            setVerified(true);
            console.log(response.data); // Handle response data as needed
        } catch (error) {
            console.error('Error verifying email:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
            {loading ? (
                <CircularProgress />
            ) : verified ? (
                <Typography variant="h5">Your email has been successfully verified!</Typography>
            ) : (
                <Typography variant="h5">Failed to verify email. Please try again or contact support.</Typography>
            )}
        </Container>
    );
};

export default EmailVerificationPage;
