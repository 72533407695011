import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
// import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../api/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Happy Camper
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;


const EditProfile = () => {
    const firstNameRef = useRef();
    const userRef = useRef();
    const errRef = useRef();

    const [firstName, setFirstName] = useState('');
    const [firstNameFocus, setFirstNameFocus] = useState(false);

    const [lastName, setLastName] = useState('');
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [campName, setCampName] = useState('');
    const [campNameFocus, setCampNameFocus] = useState(false);

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [streetName, setStreetName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const [email, setEmail] = useState('');

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const getDashboard = async () => {
            try {
                const response = await axiosPrivate.get('/api/camps/profile');
                setCampName(response.data?.name);
                setStreetName(response.data.address?.street);
                setCity(response.data.address?.city);
                setState(response.data.address?.state);
                setPostalCode(response.data.address?.postal_code);
                setCountry(response.data.address?.country);
            } catch (err) {
                console.error(err);
                navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }
        getDashboard();
        // firstNameRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    const handleSubmit = async () => {
        try {
            const response = await axiosPrivate.put('/api/camps/', JSON.stringify({
                name: campName,
                "address": {
                    street: streetName,
                    city: city,
                    state: state,
                    postal_code: postalCode,
                    country: country,
                },
            }),
                {
                    headers: { 'Content-Type': 'application/json' },
                });
            navigate('/profile');
        }
        catch (err) {
            console.error(err);
        }
    }

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 10 }}>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Edit Profile
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}> {/** on submit */}
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                ref={firstNameRef}
                                autoFocus
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="campName"
                                label="Camp Name"
                                name="campName"
                                onChange={(e) => setCampName(e.target.value)}
                                value={campName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="streetAddress"
                                label="Street Address"
                                name="streetAddress"
                                onChange={(e) => setStreetName(e.target.value)}
                                value={streetName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="city"
                                label="City"
                                name="city"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="state"
                                label="State/province"
                                name="state"
                                onChange={(e) => setState(e.target.value)}
                                value={state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="postalCode"
                                label="Postal code"
                                name="postalCode"
                                onChange={(e) => setPostalCode(e.target.value)}
                                value={postalCode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="country"
                                label="Country"
                                name="country"
                                onChange={(e) => setCountry(e.target.value)}
                                value={country}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                ref={userRef}
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                onFocus={() => setUserFocus(true)}
                                onBlur={() => setUserFocus(false)}
                            />
                        </Grid>
                        <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                            {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                        {/*    4 to 24 characters.<br />
                            Must begin with a letter.<br />
                            Letters, numbers, underscores, hyphens allowed.
                        </p>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </Grid> */}
                    </Grid>
                    <Button
                        type="submit"
                        disabled={!validName ? true : false}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Update Profile
                    </Button>

                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container >
    )
}

export default EditProfile;