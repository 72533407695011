import SignIn from './pages/SignIn';
import StickyFooter from './components/StickyFooter';
import LandingPage from './pages/LandingPage';
import { CssBaseline } from '@mui/material';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import CreateSession from './pages/CreateSession';
import SessionDetails from './pages/SessionDetails';
import EditProfile from './pages/EditProfile';
import CreateCamper from './pages/CreateCamper';
import Support from './pages/Support';
import CamperDetails from './pages/CamperDetails';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './pages/CheckoutForm';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Campers from './pages/Campers';
import Counselors from './pages/Counselors';
import NoPage from './pages/NoPage';
import AuthContext, { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useContext } from 'react';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import ResponsiveAppBarNotLoggedIn from './components/ResponsiveAppBarNotLoggedIn';
import Pricing from './pages/Pricing';
import AddCampersSelection from './pages/AddCampersSelection';
import PayPage from './pages/PayPage';
import AddCampersFile from './pages/AddCampersFile';
import AddCampersFileToCampersList from './pages/AddCampersFileToCampersList';
import EditCamperPage from './pages/EditCamperPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CreateCounselor from './pages/CreateCounselor';
import CounselorDetails from './pages/CounselorDetails';
import { AlertProvider } from './context/AlertContext';
import AlertBanner from './components/AlertBanner';
import GroupDetailsPage from './pages/GroupDetailsPage';
import CheckInDetails from './pages/CheckInDetails';
import LogsPage from './pages/LogsPage';


function App() {
  const { auth } = useContext(AuthContext);
  // const { alert, resetAlert, setSuccess, setError } = useAlert();
  console.log(auth);
  console.log("auth " + auth?.accessToken);
  console.log(auth?.roles);
  // console.log(Object.keys(auth?.roles)[0])
  return (
    <div className='App'>
      <CssBaseline />
      {auth?.accessToken !== null && auth?.accessToken !== undefined && auth?.roles && (Object.keys(auth?.roles)[0] === "Owner" || Object.keys(auth?.roles)[0] === "Admin") ? <ResponsiveAppBar> </ResponsiveAppBar> : <ResponsiveAppBarNotLoggedIn></ResponsiveAppBarNotLoggedIn>}
      <AlertProvider>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/confirm-email/:token" element={<EmailVerificationPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route path="/add-camper" element={<CreateCamper />} />
              <Route path="/edit-camper/:camperID" element={<EditCamperPage />} />
              <Route path="/add-camper-file" element={<AddCampersFileToCampersList />} />
              <Route path="/support" element={<Support />} />
              <Route path="/campers" element={<Campers />} />
              <Route path="/counselors" element={<Counselors />} />
              <Route path="/create-counselor" element={<CreateCounselor />} />
              <Route path="/view-counselor-details/:counselorId" element={<CounselorDetails />} />
              <Route path="/view-camper-details/:camperID" element={<CamperDetails />} />
              <Route path="/create-session" element={<CreateSession />} />
              <Route path="sessions/:sessionID/groups/:groupID" element={<GroupDetailsPage />} />
              {/* <Route path="/session-payment/:sessionID" element={<CheckoutForm />} /> */}
              <Route path="/view-session-details/:sessionID" element={<SessionDetails />} />
              <Route path="/sessions/:sessionID/check-in-details/:checkInID" element={<CheckInDetails />} />
              {/* <Route path="/add-campers-file/:sessionID" element={<AddCampersFile />} /> */}
              {/* <Route path="/pay-session/:sessionID" element={<PayPage />} /> */}
              {/* <Route path="/add-campers-selection/:sessionID" element={<AddCampersSelection />} /> */}
              <Route path="/camper-logs/:camperID/date/:date" element={<LogsPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
        <AlertBanner></AlertBanner>
      </AlertProvider>
    </div>
  );
}

export default App;
