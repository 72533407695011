import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Tooltip, IconButton, Container, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, TablePagination, Paper, Button, Checkbox, Typography, LinearProgress, Grid } from '@mui/material';
import { useAlert } from '../context/AlertContext';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import theme from '../theme'
import AddCamperDialog from './SessionDetailsSections/AddCamperDialog';
import AddCounselorDialog from './SessionDetailsSections/AddCounselorDialog';

const GroupDetailsPage = () => {
    // Sample data
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState(null);
    const [campers, setCampers] = useState([]);
    const [counselors, setCounselors] = useState([]);
    const [selectedCampers, setSelectedCampers] = useState([]);
    const [selectAllCampers, setSelectAllCampers] = useState(false);
    const [selectedCounselors, setSelectedCounselors] = useState([]);
    const [selectAllCounselors, setSelectAllCounselors] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const params = useParams();

    const [sessionCampers, setSessionCampers] = useState([]);
    const [sessionCounselors, setSessionCounselors] = useState([]);

    const [availableCampers, setAvailableCampers] = useState([]);
    const [availableCounselors, setAvailableCounselors] = useState([]);

    // Pagination state for campers
    const [camperPage, setCamperPage] = useState(0);
    const [camperRowsPerPage, setCamperRowsPerPage] = useState(5);

    // Pagination state for counselors
    const [counselorPage, setCounselorPage] = useState(0);
    const [counselorRowsPerPage, setCounselorRowsPerPage] = useState(5);
    const { alert, resetAlert, setSuccess, setError } = useAlert();
    const [isAddCamperDialogOpen, setIsAddCamperDialogOpen] = useState(false);
    const [isAddCounselorDialogOpen, setIsAddCounselorDialogOpen] = useState(false);


    const textFieldHeight = 56; // Adjust based on your TextField size
    const containedIconButtonStyle = {
        backgroundColor: theme.palette.primary.main, // Theme primary color
        color: theme.palette.primary.contrastText, // Contrast text color
        height: textFieldHeight,
        width: textFieldHeight,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Darker shade on hover
        },
    };

    const handleOpenAddCamperDialog = () => {
        setIsAddCamperDialogOpen(true);
    };
    const handleOpenAddCounselorDialog = () => {
        setIsAddCounselorDialogOpen(true);
    };

    const handleCloseAddCamperDialog = () => {
        setIsAddCamperDialogOpen(false);
    };
    const handleCloseAddCounselorDialog = () => {
        setIsAddCounselorDialogOpen(false);
    };

    // Handle change page for campers
    const handleChangeCamperPage = (event, newPage) => {
        setCamperPage(newPage);
    };

    // Handle change rows per page for campers
    const handleChangeCamperRowsPerPage = (event) => {
        setCamperRowsPerPage(parseInt(event.target.value, 10));
        setCamperPage(0);
    };

    // Handle change page for counselors
    const handleChangeCounselorPage = (event, newPage) => {
        setCounselorPage(newPage);
    };

    // Handle change rows per page for counselors
    const handleChangeCounselorRowsPerPage = (event) => {
        setCounselorRowsPerPage(parseInt(event.target.value, 10));
        setCounselorPage(0);
    };

    const handleToggleCamper = (camperId) => {
        const currentIndex = selectedCampers.indexOf(camperId);
        let newSelected = [...selectedCampers];

        if (currentIndex === -1) {
            newSelected.push(camperId);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        setSelectedCampers(newSelected);
    };

    const handleToggleCounselor = (counselorId) => {
        const currentIndex = selectedCounselors.indexOf(counselorId);
        let newSelected = [...selectedCounselors];

        if (currentIndex === -1) {
            newSelected.push(counselorId);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        setSelectedCounselors(newSelected);
    };

    const handleSelectAllCampers = () => {
        if (!selectAllCampers) {
            setSelectedCampers(campers.map(camper => camper._id));
        } else {
            setSelectedCampers([]);
        }
        setSelectAllCampers(!selectAllCampers);
    };

    const handleSelectAllCounselors = () => {
        if (!selectAllCounselors) {
            setSelectedCounselors(counselors.map(counselor => counselor._id));
        } else {
            setSelectedCounselors([]);
        }
        setSelectAllCounselors(!selectAllCounselors);
    };

    const handleAddSelectedCampers = async (selectedCamperIds) => {
        try {
            await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}/groups/${params.groupID}/add-campers/`, { camperIds: selectedCamperIds });
            setIsAddCamperDialogOpen(false);
            setSuccess("Successfully added campers")
            const addedCampers = sessionCampers.filter(camper => selectedCamperIds.includes(camper._id));

            // Update the state to include the newly added campers
            setCampers(prevCampers => [...prevCampers, ...addedCampers]);
        }
        catch (err) {
            console.error(err.response.data);
            setError(err.response.data);
        }
    };

    const handleAddSelectedCounselors = async (selectedCounselorIds) => {
        // onAddCamper(selectedCamperIds);
        try {
            await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}/groups/${params.groupID}/add-counselors/`, { counselorIds: selectedCounselorIds });
            setIsAddCounselorDialogOpen(false);
            setSuccess("Successfully added counselors");
            const addedCounselors = sessionCounselors.filter(counselor => selectedCounselorIds.includes(counselor._id));
            setCounselors(prevCounselors => [...prevCounselors, ...addedCounselors])
        }
        catch (err) {
            setError(err.response.data);
        }
    };

    const handleRemoveSelectedCampers = async () => {
        try {
            console.log(selectedCampers);
            const response = await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}/groups/${params.groupID}/remove-campers`, { camperIds: selectedCampers })
            setSuccess(response.data.message);
            const updatedCampers = campers.filter(camper => !selectedCampers.includes(camper._id));
            setCampers(updatedCampers);
            setSelectedCampers([]); // Clear selection after removal
        }
        catch (err) {
            console.error(err.response.data);
            setError(err.response.data.message);
        }
    };

    const handleRemoveSelectedCounselors = async () => {
        try {
            const response = await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}/groups/${params.groupID}/remove-counselors`, { counselorIds: selectedCounselors })
            setSuccess(response.data.message);
            const updatedCounselors = counselors.filter(counselor => !selectedCounselors.includes(counselor._id));
            setCounselors(updatedCounselors);
            setSelectedCounselors([]); // Clear selection after removal
        }
        catch (err) {
            setError(err.response.data.message);
        }
    };
    const fetchGroupDetails = async () => {
        try {
            console.log(`/api/camps/sessions/${params.sessionID}/groups/${params.groupID}`)
            setIsLoading(true);
            const response = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/groups/${params.groupID}`); // Replace with your actual API endpoint
            const groupDetails = response.data;
            console.log(groupDetails);
            // Assuming the API returns an object with 'campers' and 'counselors' arrays
            setName(groupDetails?.name)
            setCampers(groupDetails.campers);
            setCounselors(groupDetails.counselors);

            const campersResponse = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/campers`);
            console.log(campersResponse.data);
            setSessionCampers(campersResponse.data);
            const campersAvailable = campersResponse.data.filter(
                camper => !groupDetails.campers.some(
                    registeredCamper => registeredCamper._id === camper._id
                )
            );
            console.log(campersAvailable);
            setAvailableCampers(campersAvailable);

            const counselorsResponse = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}/counselors`);
            console.log(counselorsResponse.data);
            setSessionCounselors(counselorsResponse.data);
            const counselorsAvailable = counselorsResponse.data.filter(
                camper => !groupDetails.counselors.some(
                    registeredCamper => registeredCamper._id === camper._id
                )
            );
            setAvailableCounselors(counselorsAvailable);
        } catch (error) {
            console.error('Error fetching group details:', error);
            // Handle errors here, e.g., show a notification to the user
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        fetchGroupDetails();
    }, []);

    return (
        < Container maxWidth="md" style={{ marginTop: '75px' }}>
            {isLoading ? <LinearProgress></LinearProgress>
                :
                <Grid container spacing={2} justifyContent="space-between">

                    <Grid item xs={12}>
                        <Typography variant="h3">{name}</Typography>
                    </Grid>

                    {/* Campers Table */}
                    <Grid item xs={10}>
                        <Typography variant="h4">Campers</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Add Campers">
                            <IconButton variant="contained" onClick={handleOpenAddCamperDialog} style={containedIconButtonStyle}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Remove Selected Campers">
                            <IconButton variant="contained" onClick={handleRemoveSelectedCampers} style={containedIconButtonStyle} disabled={selectedCampers.length === 0}>
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            {/* <Button onClick={handleSelectAllCampers}>
                                    {selectAllCampers ? 'Deselect All' : 'Select All'}
                                </Button> */}
                                            <Checkbox
                                                checked={selectAllCampers}
                                                onChange={handleSelectAllCampers}
                                                inputProps={{ 'aria-label': 'Select all Campers' }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Gender</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Date of Birth</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campers
                                        .slice(camperPage * camperRowsPerPage, camperPage * camperRowsPerPage + camperRowsPerPage)
                                        .map((camper) => (
                                            <TableRow key={camper?._id}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selectedCampers.includes(camper?._id)}
                                                        onChange={() => handleToggleCamper(camper?._id)}
                                                    />
                                                </TableCell>
                                                <TableCell>{camper?.first_name}</TableCell>
                                                <TableCell>{camper?.last_name}</TableCell>
                                                <TableCell>{camper?.gender}</TableCell>
                                                <TableCell>{camper?.date_of_birth?.split("T")[0]}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={campers.length}
                                rowsPerPage={camperRowsPerPage}
                                page={camperPage}
                                onPageChange={handleChangeCamperPage}
                                onRowsPerPageChange={handleChangeCamperRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                            />
                        </TableContainer>
                    </Grid>
                    <AddCamperDialog
                        open={isAddCamperDialogOpen}
                        onClose={handleCloseAddCamperDialog}
                        campers={availableCampers}
                        onAddSelectedCampers={handleAddSelectedCampers}
                    />

                    {/* Counselors Table */}
                    {/* Campers Table */}
                    <Grid item xs={10}>
                        <Typography variant="h4">Counselors</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Add Counselors">
                            <IconButton variant="contained" onClick={handleOpenAddCounselorDialog} style={containedIconButtonStyle}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Remove Selected Counselors">
                            <IconButton variant="contained" onClick={handleRemoveSelectedCounselors} style={containedIconButtonStyle} disabled={selectedCounselors.length === 0}>
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            {/* <Button onClick={handleSelectAllCounselors}>
                                    {selectAllCounselors ? 'Deselect All' : 'Select All'}
                                </Button> */}
                                            <Checkbox
                                                checked={selectAllCounselors}
                                                onChange={handleSelectAllCounselors}
                                                inputProps={{ 'aria-label': 'Select all Counselors' }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>User Role</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {counselors
                                        .slice(counselorPage * counselorRowsPerPage, counselorPage * counselorRowsPerPage + counselorRowsPerPage)
                                        .map((counselor) => (
                                            <TableRow key={counselor?._id}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selectedCounselors.includes(counselor?._id)}
                                                        onChange={() => handleToggleCounselor(counselor?._id)}
                                                    />
                                                </TableCell>
                                                <TableCell>{counselor?.first_name}</TableCell>
                                                <TableCell>{counselor?.last_name}</TableCell>
                                                <TableCell>{Object.keys(counselor?.roles)}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={counselors.length}
                                rowsPerPage={counselorRowsPerPage}
                                page={counselorPage}
                                onPageChange={handleChangeCounselorPage}
                                onRowsPerPageChange={handleChangeCounselorRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                            />
                        </TableContainer>
                        <AddCounselorDialog
                            open={isAddCounselorDialogOpen}
                            onClose={handleCloseAddCounselorDialog}
                            counselors={availableCounselors}
                            onAddSelectedCounselors={handleAddSelectedCounselors}
                        />
                    </Grid>
                </Grid>
            }
        </ Container >
    );
};

export default GroupDetailsPage;
