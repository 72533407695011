import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Button, Typography, Checkbox } from '@mui/material';
import { Container, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';


const AddGroupDialog = ({ open, onClose, campers, counselors, onCreateGroup }) => {
    const [selectedCampers, setSelectedCampers] = useState([]);
    const [selectedCounselors, setSelectedCounselors] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [camperPage, setCamperPage] = useState(0);
    const [counselorPage, setCounselorPage] = useState(0);
    const [camperRowsPerPage, setCamperRowsPerPage] = useState(5);
    const [counselorRowsPerPage, setCounselorRowsPerPage] = useState(5);

    const handleToggleCounselor = (counselorId) => {
        const currentIndex = selectedCounselors.indexOf(counselorId);
        const newChecked = [...selectedCounselors];

        if (currentIndex === -1) {
            newChecked.push(counselorId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedCounselors(newChecked);
    };
    const handleToggleCamper = (camperId) => {
        const currentIndex = selectedCampers.indexOf(camperId);
        const newChecked = [...selectedCampers];

        if (currentIndex === -1) {
            newChecked.push(camperId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedCampers(newChecked);
    };

    const handleAddCampers = () => {
        onCreateGroup(groupName, selectedCampers, selectedCounselors);
        // onClose(); // Close the dialog
    };

    const handleSelectCamper = (event, camper) => {
        const selectedIndex = selectedCampers.indexOf(camper);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCampers, camper);
        } else if (selectedIndex >= 0) {
            newSelected = newSelected.concat(selectedCampers.slice(0, selectedIndex), selectedCampers.slice(selectedIndex + 1));
        }

        setSelectedCampers(newSelected);
    };

    const handleSelectCounselor = (event, counselor) => {
        const selectedIndex = selectedCounselors.indexOf(counselor);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCounselors, counselor);
        } else if (selectedIndex >= 0) {
            newSelected = newSelected.concat(selectedCounselors.slice(0, selectedIndex), selectedCounselors.slice(selectedIndex + 1));
        }

        setSelectedCounselors(newSelected);
    };

    const isSelectedCamper = (camper) => selectedCampers?.indexOf(camper) !== -1;
    const isSelectedCounselor = (counselor) => selectedCounselors?.indexOf(counselor) !== -1;

    const handleCamperSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = campers.map((camper) => camper._id);
            setSelectedCampers(newSelecteds);
            return;
        }
        setSelectedCampers([]);
    };

    const handleCounselorSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = counselors.map((counselor) => counselor._id);
            setSelectedCounselors(newSelecteds);
            return;
        }
        setSelectedCounselors([]);
    };

    const handleCamperChangePage = (event, newPage) => {
        setCamperPage(newPage);
    };

    const handleCounselorChangePage = (event, newPage) => {
        setCounselorPage(newPage);
    };

    const handleCounselorChangeRowsPerPage = (event) => {
        setCounselorRowsPerPage(parseInt(event.target.value, 10));
        setCounselorPage(0);
    };

    const handleCamperChangeRowsPerPage = (event) => {
        setCamperRowsPerPage(parseInt(event.target.value, 10));
        setCamperPage(0);
    };

    const visibleCampers = campers?.slice(camperPage * camperRowsPerPage, camperPage * camperRowsPerPage + camperRowsPerPage);
    const visibleCounselors = counselors?.slice(counselorPage * counselorRowsPerPage, counselorPage * counselorRowsPerPage + counselorRowsPerPage);


    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <DialogTitle>Create Group</DialogTitle>
            <DialogContent>
                {counselors?.length > 0 && campers?.length > 0 ?
                    <Container>
                        <TextField
                            fullWidth
                            label="Group Name"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            margin="normal"
                            sx={{ mb: 2 }}
                        />
                        <Typography variant="h6" gutterBottom>
                            Select Counselors
                        </Typography>
                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={selectedCounselors.length > 0 && selectedCounselors.length < counselors.length}
                                                checked={counselors.length > 0 && selectedCounselors.length === counselors.length}
                                                onChange={handleCounselorSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Role</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {visibleCounselors.map((counselor) => (
                                        <TableRow key={counselor._id}
                                            selected={isSelectedCounselor(counselor._id)}
                                            onClick={(event) => handleSelectCounselor(event, counselor._id)}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isSelectedCounselor(counselor._id)}
                                                />
                                            </TableCell>
                                            <TableCell>{counselor?.first_name}</TableCell>
                                            <TableCell>{counselor?.last_name}</TableCell>
                                            <TableCell>{Object.keys(counselor?.roles)[0]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={counselors.length}
                                rowsPerPage={counselorRowsPerPage}
                                page={counselorPage}
                                onPageChange={handleCounselorChangePage}
                                onRowsPerPageChange={handleCounselorChangeRowsPerPage}
                            />
                        </TableContainer>
                        <Typography variant="h6" gutterBottom>
                            Select Campers
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={selectedCampers.length > 0 && selectedCampers.length < campers.length}
                                                checked={campers.length > 0 && selectedCampers.length === campers.length}
                                                onChange={handleCamperSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Date of Birth</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Gender</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {visibleCampers.map((camper) => (
                                        <TableRow key={camper._id}
                                            selected={isSelectedCamper(camper._id)}
                                            onClick={(event) => handleSelectCamper(event, camper._id)}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isSelectedCamper(camper._id)}
                                                />
                                            </TableCell>
                                            <TableCell>{camper.first_name}</TableCell>
                                            <TableCell>{camper.last_name}</TableCell>
                                            <TableCell>{camper.date_of_birth?.split('T')[0]}</TableCell>
                                            <TableCell>{camper.gender}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={campers.length}
                                rowsPerPage={camperRowsPerPage}
                                page={camperPage}
                                onPageChange={handleCamperChangePage}
                                onRowsPerPageChange={handleCamperChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Container>

                    : <Typography>Unable to create group without at least one counselor and one camper to select</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddCampers} disabled={!groupName || selectedCounselors.length === 0 || selectedCampers.length === 0}>Create Group</Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddGroupDialog;