import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, Paper, Button, Typography, Checkbox, Container, CssBaseline, CircularProgress, useTheme, useMediaQuery, Tooltip, IconButton, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import theme from '../../theme.js'
import AddCamperDialog from './AddCamperDialog.js';
import { useAlert } from '../../context/AlertContext.js';
import Fuse from 'fuse.js';

const SessionCampersTable = ({ title, campers, availableCampers, onAddCamper, onRemoveCampers }) => {
    const [selectedCampers, setSelectedCampers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddCamperDialogOpen, setIsAddCamperDialogOpen] = useState(false);

    const { alert, resetAlert, setSuccess } = useAlert();
    const [visibleCampers, setVisibleCampers] = useState([]);

    // Update visibleCampers whenever campers data changes
    useEffect(() => {
        setVisibleCampers(campers);
    }, [campers]);
    const handleOpenAddCamperDialog = () => {
        setIsAddCamperDialogOpen(true);
    };

    const handleCloseAddCamperDialog = () => {
        setIsAddCamperDialogOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddSelectedCampers = (selectedCamperIds) => {
        onAddCamper(selectedCamperIds);
        setIsAddCamperDialogOpen(false);
        setSuccess("Successfully added campers")
    };

    const handleRemoveSelectedCampers = () => {
        onRemoveCampers(selectedCampers);
        setSuccess("Successfully removed selected campers")
    }

    const handleSelectCamper = (event, camperId) => {
        const selectedIndex = selectedCampers.indexOf(camperId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedCampers, camperId];
        } else {
            newSelected = newSelected.concat(
                selectedCampers.slice(0, selectedIndex),
                selectedCampers.slice(selectedIndex + 1)
            );
        }

        setSelectedCampers(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = campers.map((camper) => camper._id);
            setSelectedCampers(newSelecteds);
            return;
        }
        setSelectedCampers([]);
    };


    const isSelected = (camperId) => selectedCampers.includes(camperId);


    const viewCamperDetails = (camperId) => {
        navigate(`/view-camper-details/${camperId}`);
    };

    const displayCampers = visibleCampers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const buttonStyle = {
        height: '56px', // Fixed height
        width: 'fit-content'
    };
    const textFieldHeight = 56; // Adjust based on your TextField size
    const containedIconButtonStyle = {
        backgroundColor: theme.palette.primary.main, // Theme primary color
        color: theme.palette.primary.contrastText, // Contrast text color
        height: textFieldHeight,
        width: textFieldHeight,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Darker shade on hover
        },
    };

    const fuseOptions = {
        includeScore: true,
        keys: ["first_name", "last_name"]
    };

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query.length > 0) {
            // Perform a search only when there's a query
            const fuse = new Fuse(campers, fuseOptions);
            const result = fuse.search(query);
            const matches = result.map(item => item.item);
            setVisibleCampers(matches);
        } else {
            // Display all campers when there's no search query
            setVisibleCampers(campers);
        }

        setPage(0); // Reset to the first page when search changes
    };



    useEffect(() => {
        // Initialize Fuse with the campers list every time the campers data changes
        const fuse = new Fuse(campers, fuseOptions);
    }, [campers]);

    return (
        <Container>
            <Grid container spacing={2} ailgnItems="center" justifyContent="space-between" style={{ marginBottom: '20px' }}>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Typography variant="h6" >{title}</Typography>
                    </Box>
                </Grid>
                {campers?.length > 0 ?
                    <Grid item xs={10} >
                        <TextField
                            variant="outlined"
                            placeholder="Search by name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            style={{ marginBottom: 20, width: '100%' }} // Adjust width as needed
                            size="medium"
                            fullWidth
                            sx={{
                                marginBottom: "20px",
                                '& .MuiInputBase-input': {
                                    height: '40px', // Adjust input height as needed
                                    padding: '10px', // Adjust padding to vertically center the text
                                },
                            }}
                        />
                    </Grid >
                    : <Grid item xs={10}> </Grid>}
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box display="flex" gap="10px">
                        <Tooltip title="Add Campers">
                            <IconButton
                                variant="contained"
                                onClick={handleOpenAddCamperDialog}
                                style={containedIconButtonStyle}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        {campers?.length > 0 ?
                            <Tooltip title="Remove Campers">
                                <IconButton variant="contained" onClick={handleRemoveSelectedCampers} style={containedIconButtonStyle} disabled={selectedCampers.length === 0}> <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            : <> </>}
                        <AddCamperDialog
                            open={isAddCamperDialogOpen}
                            onClose={handleCloseAddCamperDialog}
                            campers={availableCampers}
                            onAddSelectedCampers={handleAddSelectedCampers}
                        />
                    </Box>
                </Grid>
                {
                    displayCampers.length > 0 ?
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={selectedCampers.length > 0 && selectedCampers.length < campers.length}
                                                    checked={campers.length > 0 && selectedCampers.length === campers.length}
                                                    onChange={handleSelectAllClick}
                                                />
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Date of Birth</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Gender</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {displayCampers.map((camper) => (
                                            <TableRow
                                                key={camper._id}
                                                selected={isSelected(camper._id)}
                                                onClick={(event) => handleSelectCamper(event, camper._id)}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isSelected(camper._id)}
                                                        onChange={(event) => handleSelectCamper(event, camper._id)}
                                                    />
                                                </TableCell>
                                                <TableCell>{camper?.first_name}</TableCell>
                                                <TableCell>{camper?.last_name}</TableCell>
                                                <TableCell>{camper.date_of_birth?.split('T')[0]}</TableCell>
                                                <TableCell>{camper?.gender}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => viewCamperDetails(camper._id)}>View Details</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    // rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={visibleCampers.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Grid>
                        :
                        <Typography variant="h6">No campers found</Typography>
                }
            </Grid >
        </Container>
    );
};

export default SessionCampersTable;