import { useState, useEffect, useContext } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, IconButton, Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Container, Typography, Checkbox, TablePagination, TextField } from '@mui/material';
import { LinearProgress } from '@mui/material';
import CustomPaginationActionsTableCounselors from '../components/CustomPaginationActionsTableCounselors';
import AuthContext from '../context/AuthProvider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import theme from '../theme';

const textFieldHeight = 56; // Adjust based on your TextField size
const containedIconButtonStyle = {
    backgroundColor: theme.palette.primary.main, // Theme primary color
    color: theme.palette.primary.contrastText, // Contrast text color
    height: textFieldHeight,
    width: textFieldHeight,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark, // Darker shade on hover
    },
};

export default function Counselors() {
    const [counselors, setCounselors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useContext(AuthContext);
    console.log(auth)

    useEffect(() => {
        const getCounselors = async () => {
            try {
                console.log(auth);
                const response = await axiosPrivate.get(`/api/camps/${auth?.camp_id}/users`);
                console.log(response.data);
                setCounselors(response.data);
                setIsLoading(false);
            }
            catch (err) {
                console.error(err);
                if (err.response.status === 401) {
                    navigate('/sign-in')
                }
            }
        }
        getCounselors();
    }, [])

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={2} marginTop="10px">
                    <Typography variant="h4" textAlign="center">Counselors</Typography>
                </Grid>
                <Grid item xs={10} marginTop="12px" align="right" >
                    <Tooltip title="Create Counselor">
                        <IconButton
                            variant="contained"
                            onClick={() => { navigate("/create-counselor") }}
                            style={containedIconButtonStyle}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                {/* <Grid item xs={3} marginTop="12px" align="right" >
                    <Button variant="contained" onClick={() => { navigate("/add-counselor-file") }} >Add Counselors File</Button>
                </Grid> */}
                {isLoading ? <Grid item xs={12}>
                    <Box sx={{ width: ' 100%' }}> <LinearProgress /></Box>
                </Grid> :
                    counselors?.length === 0 ?
                        <Grid item xs={12}>
                            <Typography variant="subtitle" textAlign="center">No counselors to show...</Typography>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <CustomPaginationActionsTableCounselors counselors={counselors}> </CustomPaginationActionsTableCounselors>
                        </Grid>}
            </Grid>
        </Container >
    )
}
