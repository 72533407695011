import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { makeStyles } from '@mui/styles';
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Paper,
    Button,
    Grid,
    CircularProgress,
    Container
} from '@mui/material';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles({
    tableContainer: {
        maxWidth: '800px',
        margin: '0 auto',
    },
    button: {
        marginTop: '16px',
    },
});

const AddCampersSelection = () => {
    const classes = useStyles();

    const [camperData, setCamperData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionDetails, setSessionDetails] = useState(null)
    const [selectedCamperIds, setSelectedCamperIds] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        const getSession = async () => {
            try {
                const response = await axiosPrivate.get(`/api/camps/sessions/${params.sessionID}`);
                console.log(response.data);
                setSessionDetails(response.data);
            }
            catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }
        setIsLoading(true);
        getSession();
        // getCampers();
    }, [])

    useEffect(() => {
        const getCampers = async () => {
            try {
                const response = await axiosPrivate.get(`/api/camps/campers/`);
                console.log(response.data);
                console.log(sessionDetails);
                const campers = response.data.filter(x => !sessionDetails.pending_campers.includes(x._id) && !sessionDetails.registered_campers.includes(x._id))
                console.log(campers)
                setCamperData(campers);
            }
            catch (err) {
                console.error(err);
            }
        }
        getCampers();
        setIsLoading(false);
    }, [sessionDetails])

    const isSelected = (id) => selectedCamperIds.indexOf(id) !== -1;

    const handleCheckboxChange = (event, id) => {
        const selectedIndex = selectedCamperIds.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCamperIds, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCamperIds.slice(1));
        } else if (selectedIndex === selectedCamperIds.length - 1) {
            newSelected = newSelected.concat(selectedCamperIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedCamperIds.slice(0, selectedIndex),
                selectedCamperIds.slice(selectedIndex + 1),
            );
        }

        setSelectedCamperIds(newSelected);
        setSelectAllChecked(false);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = camperData.map((n) => n._id);
            setSelectedCamperIds(newSelecteds);
            setSelectAllChecked(true);
        } else {
            setSelectedCamperIds([]);
            setSelectAllChecked(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage - 1);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSubmit = async (event) => {
        try {
            console.log(selectedCamperIds);
            const response = await axiosPrivate.post(`/api/camps/sessions/${params.sessionID}`,
                JSON.stringify(selectedCamperIds),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            console.log(response);
            navigate(`/view-session-details/${params.sessionID}`);
        }
        catch (err) {
            console.log(err);
        }
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, camperData.length - page * rowsPerPage);

    // Inside your component after existing useEffects and other logic
    const selectedCampers = camperData.filter(camper => selectedCamperIds.includes(camper._id));

    return (
        isLoading ? <Box style={{ textAlign: 'center' }}>
            <CircularProgress />
        </Box> :
            <Container>
                <Box mt={4} mb={4}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Selected Campers
                    </Typography>
                    {selectedCampers.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>Date of Birth</TableCell>
                                        <TableCell>Gender</TableCell>
                                        <TableCell align="center">Remove</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedCampers.map((camper) => (
                                        <TableRow key={camper._id}>
                                            <TableCell>{camper.first_name}</TableCell>
                                            <TableCell>{camper.last_name}</TableCell>
                                            <TableCell>{camper.date_of_birth.split('T')[0]}</TableCell>
                                            <TableCell>{camper.gender}</TableCell>
                                            <TableCell align="center">
                                                <Checkbox
                                                    checked={selectedCamperIds.includes(camper._id)}
                                                    onChange={(event) => handleCheckboxChange(event, camper._id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography align="center">No campers selected yet.</Typography>
                    )}
                </Box>
                <Box mt={4}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Add Campers to Session
                    </Typography>
                    {!isLoading && camperData.length === 0 ? <Typography className={classes.tableContainer}> No campers to add from campers list </Typography>
                        :
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectAllChecked}
                                                onChange={handleSelectAllClick}
                                                inputProps={{ 'aria-label': 'Select all campers' }}
                                            />
                                        </TableCell>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>Date of Birth</TableCell>
                                        <TableCell>
                                            Gender
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? camperData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : camperData
                                    ).map((camper) => {
                                        const isItemSelected = isSelected(camper._id);
                                        return (
                                            <TableRow
                                                hover
                                                key={camper._id}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onChange={(event) => handleCheckboxChange(event, camper._id)}
                                                    />
                                                </TableCell>
                                                <TableCell>{camper.first_name}</TableCell>
                                                <TableCell>{camper.last_name}</TableCell>
                                                <TableCell>{camper.date_of_birth.split('T')[0]}</TableCell>
                                                <TableCell>{camper.gender}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={5} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                                        Add to Session
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Pagination
                                        component="div"
                                        count={Math.ceil(camperData.length / rowsPerPage)}
                                        page={page + 1}
                                        onChange={handlePageChange}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Grid>
                            </Grid>
                        </TableContainer>
                    }
                </Box>
            </Container>
    );
};

export default AddCampersSelection;