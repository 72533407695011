import { useState } from "react";
import { Button, Container, Grid, Paper, Typography, CircularProgress, LinearProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import theme from "../theme";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton, List, ListItem, ListItemText
} from '@mui/material';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const styles = {
    paper: {
        padding: 16,
        marginBottom: 16,
    },
    input: {
        display: "none",
    },
};


const sampleCampers = [
    { id: 1, firstName: 'John', lastName: 'Doe', gender: 'Male', dateOfBirth: '2010-01-01', group: 'A', mealPlan: 'Yes', busPlan: 'No', healthInfo: 'None', specialNotes: 'N/A', guardianFirstName: 'Jane', guardianLastName: 'Doe', guardianEmail: 'janedoe@example.com', guardianPhoneNumber: '123-456-7890' },
    { id: 2, firstName: 'Mary', lastName: 'Smith', gender: 'Female', dateOfBirth: '2011-02-02', group: 'B', mealPlan: 'No', busPlan: 'Yes', healthInfo: 'Allergic to nuts', specialNotes: 'Bring EpiPen', guardianFirstName: 'John', guardianLastName: 'Smith', guardianEmail: 'johnsmith@example.com', guardianPhoneNumber: '098-765-4321' },
];

const SampleTable = () => (
    <Paper>
        <TableContainer style={{ maxHeight: 440, overflow: 'auto' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Gender</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Date of Birth</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Group</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Meal Plan</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Bus plan</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Health Info</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Special Notes</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Guardian First Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Guardian Last Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Guardian Email</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Guardian Phone</TableCell>
                        {/* Add other headers here */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sampleCampers.map((camper) => (
                        <TableRow key={camper.id}>
                            <TableCell>{camper.firstName}</TableCell>
                            <TableCell>{camper.lastName}</TableCell>
                            <TableCell>{camper.gender}</TableCell>
                            <TableCell>{camper.dateOfBirth}</TableCell>
                            <TableCell>{camper.group}</TableCell>
                            <TableCell>{camper.mealPlan}</TableCell>
                            <TableCell>{camper.busPlan}</TableCell>
                            <TableCell>{camper.healthInfo}</TableCell>
                            <TableCell>{camper.specialNotes}</TableCell>
                            <TableCell>{camper.guardianFirstName}</TableCell>
                            <TableCell>{camper.guardianLastName}</TableCell>
                            <TableCell>{camper.guardianEmail}</TableCell>
                            <TableCell>{camper.guardianPhoneNumber}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
);



function AddCampersFileToCampersList() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState('');

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        // Handle file upload logic
        console.log(selectedFile);
        try {
            setIsLoading(true);
            var bodyFormData = new FormData();
            bodyFormData.append("file", selectedFile);
            const response = await axiosPrivate({
                method: "post",
                url: `/api/camps/campers/file-upload`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true
            }
            );
            console.log(response.data);
            navigate(`/campers`);
        }
        catch (err) {
            console.log(err);
            setErrMsg(err.response.data[0].message);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="md" spacing={2} sx={{ marginTop: 12 }}>
            {
                isLoading ? <LinearProgress /> : <></>
            }
            <Paper style={styles.paper}>
                <Typography variant="h5" gutterBottom>
                    Add Campers from File
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <input
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={styles.input}
                            id="file-upload"
                            type="file"
                            onChange={handleFileInputChange}
                        />
                        <label htmlFor="file-upload">
                            <Button
                                variant="contained"
                                component="span"
                                color="primary"
                                size="large"
                            >
                                Select File
                            </Button>
                        </label>
                    </Grid>
                    <Grid item>
                        {selectedFile && (
                            <Typography variant="body1">
                                Selected File: {selectedFile.name}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!selectedFile}
                            onClick={handleFileUpload}
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Paper style={styles.paper}>
                <Typography variant="body1">
                    Please follow this format for the file:
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div">
                    <Typography gutterBottom>
                        Ensure your Excel or CSV file contains the following fields:
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        Required Fields:
                    </Typography>
                    <List dense={true}>
                        <ListItem><ListItemText primary="First Name (first name, prénom)" /></ListItem>
                        <ListItem><ListItemText primary="Last Name (last name, nom, nom de famille)" /></ListItem>
                        <ListItem><ListItemText primary="Gender (gender, sexe, genre)" /></ListItem>
                        <ListItem><ListItemText primary="Date of Birth (date of birth, dob, date de naissance)" /></ListItem>
                    </List>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        Optional Fields:
                    </Typography>
                    <List dense={true}>
                        <ListItem><ListItemText primary="Group (group, groupe)" /></ListItem>
                        <ListItem><ListItemText primary="Meal Plan (meal plan, plan de repas)" /></ListItem>
                        <ListItem><ListItemText primary="Bus Plan (bus plan, plan de bus)" /></ListItem>
                        <ListItem><ListItemText primary="Health Info (health info, informations de santé)" /></ListItem>
                        <ListItem><ListItemText primary="Special Notes (special notes, notes spéciales)" /></ListItem>
                        <ListItem><ListItemText primary="Guardian First Name (guardian first name, prénom du tuteur)" /></ListItem>
                        <ListItem><ListItemText primary="Guardian Last Name (guardian last name, nom de famille du tuteur)" /></ListItem>
                        <ListItem><ListItemText primary="Guardian Email (guardian email, email du tuteur)" /></ListItem>
                        <ListItem><ListItemText primary="Guardian Phone Number (guardian phone number, numéro de téléphone du tuteur)" /></ListItem>
                    </List>
                    <Typography variant="body2">
                        The first row of your file should include these column headers in English or French as provided.
                    </Typography>
                    <Typography variant="body1">
                        Sample file:
                        <a href="./sample_file.csv" download>
                            sample_file.csv
                        </a>
                    </Typography>
                </Typography>
            </Paper>
            <Typography variant="h6">Sample Table</Typography>
            <SampleTable></SampleTable>
        </Container>
    );
}

export default AddCampersFileToCampersList;
