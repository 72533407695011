import React from 'react'
import { Container, LinearProgress } from "@mui/material";
import GroupsTable from './GroupsTable';

const Groups = ({ campers, counselors, groups, onAddGroup, onRemoveGroups, sessionID, isLoading }) => {
    return (
        isLoading ? <LinearProgress></LinearProgress> :
            <Container>
                <GroupsTable title="Groups" groups={groups} campers={campers} counselors={counselors} onRemoveGroups={onRemoveGroups} onAddGroup={onAddGroup} sessionID={sessionID}></GroupsTable>
            </Container>
    )
}

export default Groups;