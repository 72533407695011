import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Container, CssBaseline, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// const theme = createTheme();


export default function Profile() {
    const axiosPrivate = useAxiosPrivate();
    const [dashboard, setDashboard] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [toDelete, setToDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    function DeleteDialog() {
        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };
        const handleDelete = () => {
            setToDelete(true);
            setOpen(false);
        }

        return (
            <div>
                <Button style={{ margin: '2px' }} variant="outlined" onClick={handleClickOpen} startIcon={<DeleteIcon />}>
                    Delete Account
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete Account?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want your account? Once deleted, your account cannot be recovered.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete}>Yes</Button>
                        <Button onClick={handleClose} autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    useEffect(() => {

        const getDashboard = async () => {
            try {
                axiosPrivate.get('/api/camps/profile').then((response) => {
                    console.log(response.data);
                    setDashboard(response.data);
                })

            } catch (err) {
                console.error(err);
                navigate('/sign-in', { state: { from: location }, replace: true });
            }
            finally {
                setIsLoading(false);
            }
        }

        getDashboard();
    }, [])

    useEffect(() => {
        const deleteCamp = async () => {
            try {
                console.log(" firing")
                if (toDelete) {
                    console.log(" firing in if")
                    axiosPrivate.delete(`/api/camps/`).then(() => {
                        navigate('/');
                    })
                }
            }
            catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }
        deleteCamp();
    }, [toDelete])

    return (
        isLoading ? <Box style={{ textAlign: 'center', marginTop: 10 }}>
            <CircularProgress />
        </Box> :
            <Container component="main" maxWidth="md" sx={{ mt: 12 }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'

                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography variant="h4">Profile</Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",

                        }}>
                            <Button style={{ margin: '2px' }} variant="contained" onClick={() => { navigate('/edit-profile') }}>Edit Profile</Button>
                            <DeleteDialog></DeleteDialog>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle">Camp Name</Typography>
                        <Typography variant="subtitle">{dashboard?.name}</Typography>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle">Camp Manager Name</Typography>
                        <Typography variant="subtitle">{dashboard?.camp_manager?.first_name + " " + dashboard?.camp_manager?.last_name}</Typography>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle">Phone #</Typography>
                        <Typography variant="subtitle">Camp Name</Typography>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle">Email Address</Typography>
                        <Typography variant="subtitle">{dashboard?.email}</Typography>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle">Address</Typography>
                        <Typography variant="subtitle">{dashboard?.address?.street + ", " + dashboard?.address?.city + ", " + dashboard?.address?.state + ", " + dashboard?.address?.country + ", " + dashboard?.address?.postal_code}</Typography>

                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle">Time Zone</Typography>
                        <Typography variant="subtitle">{dashboard?.time_zone}</Typography>

                    </Box>

                </Box>
            </Container>
    )
}
